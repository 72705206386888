import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Businessecstyle from './OurBusinessesSection.module.css'
import Button from '@mui/material/Button'
import img1 from '../../assets/images/OurBusinessesSection.svg'
import img2 from '../../assets/images/pexels-chanaka.svg'
import img3 from '../../assets/images/safe_shopping.svg'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { id } from 'date-fns/locale'


const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text" >
            {isReadMore ? text?.slice(0, 160) : text}
            <span onClick={toggleReadMore} className="read-or-hide" style={{ color: '#16469D' }}>
                {isReadMore ? "...Read more" : " Show less"}
            </span>
        </p>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props

    // console.log("value sdad   "+value)
    // console.log("index sdad     "+index);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={Businessecstyle.secondpart} sx={{ pt: 15, pl: 10, pr: 10 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {

    // console.log("9ndex"+index);
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}
export default function OurBusinessesSection() {
    // GET with Axios
    const [value, setValue] = React.useState(0)
    const [data, setData] = useState([])
    const [TABS, setTABS] = useState([]);
    const [backgroundImage, setBackgroundImage] = React.useState("")
    const [render, setRender] = useState(false);
    const getAllBusinessData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/business/getAllBusinessDetails`
            )
            setData(res.data)


        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllBusinessData()
    }, [])



    const getImages = async (imgname) => {

        const response = await fetch(
            `${process.env.REACT_APP_COSMOSADMIN}/business/file/${imgname}`
        );

        const buffer = await response.arrayBuffer();

        const binaryData = new Uint8Array(buffer);
        //   console.log(binaryData)
        //   const base64Image = btoa(String.fromCharCode.apply(null, binaryData));

        let binaryString = '';
        for (let i = 0; i < binaryData.length; i++) {
            binaryString += String.fromCharCode(binaryData[i]);
        }
        const base64Image = btoa(binaryString);
        const dataUrl = `data:image/jpeg;base64,${base64Image}`;

        return dataUrl;
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                const back = await Promise.all(data.map((d) => getImages(d.newBusinessImageName)));
                // const back = getImages(data[0].newBusinessImageName)
                setBackgroundImage(back[0]);
                setValue(0)
                setTABS(back);
                setRender(true);
                // console.log(backgroundImage);
                // console.log(TABS);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [data])






    const handleChange = (event, newValue) => {

        // console.log(newValue)
        setValue(newValue)
        setBackgroundImage(TABS[newValue])
    }

    React.useEffect(() => {

        if (TABS.length === 0) {
            return; // Do nothing if TABS is empty
        }
        const interval = setInterval(() => {

            let tt = parseInt((value + 1) % TABS.length);

            // console.log(TABS.length);
            // console.log(tt+"interval")
            setValue(tt)
        }, 5000) // change 5000 to the number of milliseconds between tab changes
        setBackgroundImage(TABS[value])
        return () => clearInterval(interval)
    }, [TABS, value])




    return (


        <div className={Businessecstyle.BusinessStart}>
            <Box className={Businessecstyle.BusinesssBoxes}  >
                <Box className={Businessecstyle.BusiBoxStart}>
                    <Typography variant='h6'>Our Business</Typography>
                    {data?.map((businesssssssss) => (

                        <Box className={Businessecstyle.Boxess0}>

                            <Box className={Businessecstyle.BusinessIMg}
                                sx={{
                                    backgroundImage: `url(${backgroundImage})`,
                                    width: '100%',
                                    height: '40vh',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    borderRadius: '5px',
                                    transition: '1s',
                                    marginTop: '2%',
                                }}>
                            </Box>
                            <h1>{businesssssssss?.business?.businessHeading}</h1>
                            <p><ReadMore>{businesssssssss?.business?.businessDesc}</ReadMore></p>
                            <a href={businesssssssss?.business?.businessLink} target="_blank">
                                <Button variant="contained" className={Businessecstyle.tabdescBtn2} > Visit Now
                                </Button>
                            </a>
                        </Box>
                    ))}
                    {/* url(${`{${process.env.REACT_APP_COSMOSADMIN}/business/file/${backgroundImage}}`}) */}
                </Box>
            </Box>
            <Grid className={Businessecstyle.BusinesMobileHide} container spacing={1} sx={{ mt: '2%', mb: '2%' }}>
                <Grid
                    onChange={handleChange}
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    className={Businessecstyle.bgimg}
                    sx={{
                        background: `linear-gradient(23.13deg, rgba(0, 0, 0, 0.509) 1.00%, rgba(0, 0, 0, 0.701) 1.94%), url(${backgroundImage}) `,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <h1 className={Businessecstyle.heading}>Our Business</h1>

                    <Box className={Businessecstyle.mybox}></Box>

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        sx={{
                            '& ': { display: 'flex', backgroundColor: 'none', alignItems: 'center', justifyContent: 'flex-start', width: '80%', padding: '10px 12px' },

                            '& button.Mui-selected': {
                                color: '#fff',
                                backgroundColor: 'none',
                            },
                        }}
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: '#fff',
                                width: 6,
                                left: 0,
                                alignItems: 'left',
                            },
                        }}
                    >

                        {data?.map((businessN, index) => {

                            return (
                                <Tab
                                    sx={{
                                        borderLeft: 6,
                                        borderColor: ' rgba(255, 255, 255, 0.5)',
                                        alignItems: 'left',
                                        maxWidth: '600px',
                                        minWidth: '400px',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        height: '15vh',
                                        paddingTop: '40px',

                                    }}
                                    disableRipple
                                    className={Businessecstyle.tabtxt}
                                    label={businessN?.business?.businessHeading}
                                    key={businessN?.business?.businessId}
                                    {...a11yProps(index + 1)}
                                ></Tab>


                            )
                        }
                        )}
                    </Tabs>


                </Grid>

                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    sx={{ background: '#F6FAFF' }}
                    className={Businessecstyle.bgimgContent}

                >
                    {data && data?.map((busin, index) => {

                        // console.log(busin)
                        return (
                            <TabPanel
                                value={value}
                                index={index}
                                key={busin?.business?.businessId}
                            >
                                <h1 key={busin?.business?.businessId}>{busin?.business?.businessHeading}</h1>
                                <p key={busin?.business?.businessId}>{busin?.business?.businessDesc}</p>
                                <a key={busin?.business?.businessId} href={busin?.business?.businessLink} target="_blank">
                                    <Button variant="contained" key={busin?.business?.businessId} className={Businessecstyle.tabdescBtn} > Visit Now
                                    </Button>
                                </a>
                            </TabPanel>
                        )
                    }
                    )}
                </Grid>
            </Grid>


            <div className={Businessecstyle.BusinesMobileStart}>
                <h6>Our Business</h6>
                {data.length > 0 && data?.map((item) => (
                    <Box className={Businessecstyle.BusinesStart}>
                        <div style={{
                            backgroundImage: `url(${process.env.REACT_APP_COSMOSADMIN}/business/file/${item.newBusinessImageName})`,
                            width: '100%',
                            height: '40vh',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                        >
                        </div>
                        <h1 style={{fontSize:'20px'}}>{item?.business?.businessHeading}</h1>
                        <p><ReadMore>{item?.business?.businessDesc}</ReadMore></p>
                        <a href={item?.business?.businessLink} target="_blank">
                            <Button variant="contained" className={Businessecstyle.tabdescBtn2}> Visit Now </Button>
                        </a>
                    </Box>
                ))}
            </div>
        </div>
    )
}
