import React, { useEffect, useState } from 'react'
import HighlightStyles from './InvestHighlight.module.css'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box';
import { Image } from 'cloudinary-react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Link } from 'react-router-dom';
import axios from 'axios';


const InvestHighlight = () => {

    const [data, setData] = useState([])

    const getInvestorData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/investmentHighlights/getAllInvestmentHighlights`
            )
            setData(res.data)
            console.log(res.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getInvestorData()
    }, [])


    return (
        <div className={HighlightStyles.highlight_main}>
            <div className={HighlightStyles.highlight_content}>
                <Typography variant='h1' sx={{
                    fontSize: '30px',
                    fontWeight: '600',
                    color: ' #585858',
                }}>
                    Investment Highlights
                </Typography>
                <div className={HighlightStyles.highlight_boxes2}>

                    {
                        data?.map((item) => {
                            return(
                                <div className={HighlightStyles.highlightbox} style={{ backgroundImage: `linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5187324929971988) 45%, rgba(0,0,0,0.27503501400560226) 100%), url(${process.env.REACT_APP_COSMOSADMIN}/investmentHighlights/file/${item?.logo})` }}>
                                    <div>
                                        <h3>{item?.highlights?.investmentHeader}</h3>
                                    <p> {item?.highlights?.investmentDetails}</p>
                                    </div>
                                </div>  
                           
                        )
                    })

                      
                    }

 {/* <Box className={HighlightStyles.highlight_box}>
                                <Image publicId={`${process.env.REACT_APP_COSMOSADMIN}/investmentHighlights/file/${item?.logo}`} cloudName="dxluokypg"  >
                                    <div>
                                        <Typography className={HighlightStyles.highlight_heading} >
                                            {item?.highlights?.investmentHeader}
                                        </Typography>
                                        <Typography className={HighlightStyles.highlight_desc} >
                                            {item?.highlights?.investmentDetails}
                                        </Typography>
                                    </div>
                                </Image>
                            </Box> */}
                </div>
            </div>
            <div className={HighlightStyles.invest_opp} >
                <Typography className={HighlightStyles.invest_title}>Investment opportunities</Typography>
                <Link to='/investopp' style={{ textDecoration: 'none' }} className={HighlightStyles.invest_button} >
                    <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>Know more <ArrowCircleRightOutlinedIcon sx={{ ml: '5px' }} /></Typography>
                </Link>
            </div>
        </div>
    )
}

export default InvestHighlight