import React, { useEffect, useState } from 'react'
import Permission from "./PermissionPage.module.css"
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { setemail, setname, setcompanyName, setmobileNumber } from './store/dataSlice';
import { permission } from './store/Permission.actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const PermissionPage = () => {
    const { invId } = useParams();

    const location = useLocation()
    const data2 = location.state

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const permissionState = useSelector((state) => state.permission)

    const [data, setData] = useState({
        name: '',
        email: '',
        mobileNumber: '',
        companyName: '',
        invId: invId,
        type: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = () => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const regexphone = /^(\+|\d)[0-9]{4,16}$/;
        const regexname = /^[A-Za-z\s]+$/; 
        if (!data.name) {
            errors.name = 'Name is required!';
        } else if (data.name.trim().length === 0) {
            errors.name = 'Name is required!';
        } else if (data.name.trim().length === 1) {
            errors.name = 'Enter a valid name with more than one letter.';
        }else if (!regexname.test(data.name)) {
            errors.name = 'Name can only contain letters and spaces!';
        }
    
    
        if (!data.email) {
            errors.email = 'Email is required!';
        } else if (!regexemail.test(data.email)) {
            errors.email = 'Please enter a valid email.';
        }
        if (!data.mobileNumber) {
            errors.mobileNumber = 'Mobile Number is required!';
        } else if (!regexphone.test(data.mobileNumber)) {
            errors.mobileNumber = 'Enter a valid phone number.';
        }
        if (!data.companyName) {
            errors.companyName = 'Please write a Company Name.';
        }else if (data.companyName.trim().length === 0) {
            errors.companyName = 'Comapny name is required!';
        } else if (data.companyName.trim().length === 1) {
            errors.companyName = 'Enter a valid name with more than one letter.';
        }
        if (!data.type) { // Validate the type field
            errors.type = 'Type is required!';
          }
        return errors;
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // console.log(data)
        // setErrors(validate(data))
        // setIsSubmit(true)
        const validationErrors = validate();
        setErrors(validationErrors);
        setIsSubmit(true);
        // }

        // useEffect(() => {
        //     if (Object.keys(errors).length === 0 && isSubmit) {
        //         dispatch(setname(data.name))
        //         dispatch(setemail(data.email))
        //         dispatch(setmobileNumber(data.mobileNumber))
        //         dispatch(setcompanyName(data.companyName))
        //         dispatch(permission(data))
        //         setIsSuccess(true)
        //         setData({
        //             name: '',
        //             email: '',
        //             mobileNumber: '',
        //             companyName: '',
        //             invId: '',
        //         })
        //         setTimeout(() => {
        //             setIsSuccess(false);
        //             console.log(invId)
        //             navigate(`/request/${invId}`)

        //         }, 2000)
        //     }
        // }, [errors, isSubmit])
        if (Object.keys(validationErrors).length === 0) {
            dispatch(setname(data.name));
            dispatch(setemail(data.email));
            dispatch(setmobileNumber(data.mobileNumber));
            dispatch(setcompanyName(data.companyName));
            dispatch(permission(data));
            setIsSuccess(true);
            setData({
                name: '',
                email: '',
                mobileNumber: '',
                companyName: '',
                invId: '',
                type:'',
            });
            setTimeout(() => {
                setIsSuccess(false);
                console.log(invId);
                navigate(`/request/${invId}`);
            }, 2000);
        }
    };



    return (
        <div className={Permission.PageMain}>
            <div className={Permission.PageStart}>
                <div className={Permission.PageBox}>
                    <Typography variant='h4'>Access to investment opportunities requires the owner's permission</Typography>
                    <Typography variant='h6'>Want to view investment oppportunities? Ask the owner to share it with you.</Typography>


                    <form onSubmit={handleSubmit}>
                        <label>Type</label>
                        <select required className={Permission.Pselect} value={data.type} onChange={(e) => handleChange(e, 'type')} style={{ width: '70%', padding: '1.8% 0', fontSize: '15px ', color: '#000', borderRadius: '6px', textIndent: '5px' }}>
                            <option value="" disabled selected >Select your Type</option>
                            <option value="investor" selected={data.type === 'investor'}>Investor</option>
                            <option value="franchisee" selected={data.type === 'franchisee'}>Franchisee</option>
                            <option value="partner" selected={data.type === 'partner'}>Partner</option>
                        </select>
                        <label>Name</label>
                        <input variant='outlined' fullWidth required
                            value={data.name}
                            onChange={(e) => handleChange(e, 'name')}
                            sx={{ bgcolor: '#FFFFFF', color: '#232536', fontWeight: '400', lineHeight: '28px', }} />
                        {errors.name && <div className={Permission.errorsPermission}>{errors.name}</div>}

                        <label>Email</label>
                        <input type='text' variant='outlined' fullWidth required
                            value={data.email}
                            onChange={(e) => handleChange(e, 'email')}
                            sx={{ bgcolor: '#FFFFFF', color: '#232536', fontWeight: '400', lineHeight: '28px', mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%' }, }} />
                        {errors.email && <div className={Permission.errorsPermission}>{errors.email}</div>}

                        <label>Phone Number</label>
                        <input type='text' label="Phone Number" variant='outlined' fullWidth required
                            value={data.mobileNumber}
                            onChange={(e) => handleChange(e, 'mobileNumber')}
                            sx={{ bgcolor: '#FFFFFF', color: '#232536', fontWeight: '400', fontSize: '15px', lineHeight: '28px', mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%' }, mb: '4%' }} />
                        {errors.mobileNumber && <div className={Permission.errorsPermission}>{errors.mobileNumber}</div>}

                        <label>Company Name</label>
                        <input type='text' label="Company Name" variant='outlined' fullWidth required
                            value={data.companyName}
                            onChange={(e) => handleChange(e, 'companyName')}
                            sx={{ bgcolor: '#FFFFFF', color: '#232536', fontWeight: '400', fontSize: '15px', lineHeight: '28px', mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%' }, mb: '4%' }} />
                        {errors.companyName && <div className={Permission.errorsPermission}>{errors.companyName}</div>}

                        {/* <input type='number' variant='outlined' fullWidth required
                            value={data.invId}
                            onChange={(e) => handleChange(e, 'invId')}
                        /> */}


                        {/* <Button className={Permission.SubmitBtn} type="submit" variant="contained" >
                                Request Access
                            </Button> */}
                        {/* <button className={`${Permission.button} ${Permission.type1}`} > */}
                        {/* 
                        <button >
                            <span> Request Access</span>
                            <div className={Permission.arrowwrapper} >
                                <div className={Permission.arrow} ></div>
                            </div>
                        </button> */}
                        <Button type="submit" variant="contained" >
                            Request Access
                        </Button>


                    </form>
                </div>
                <div className={Permission.Lockimg}>
                    <img src='https://res.cloudinary.com/dtffniutw/image/upload/v1688636156/lock_q1g34v.gif' />
                </div>
            </div>
        </div >
    )
}

export default PermissionPage
