import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import BannerStyles from './InvVideo.module.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';

const InvVideo = () => {
  const { channelId } = useParams();

  const [data, setData] = useState([]);

  const getTeamData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_COSMOSINV}/channel/getChannelById/${channelId}`
      );
      setData(res.data);

      console.log(res.data);
    } catch (error) {
      console.log(error + " check");
    }
  };

  useEffect(() => {
    getTeamData();
  }, []);

  // // Make sure dataVideo is an array before mapping over it
  // if (!Array.isArray(data)) {
  //   return null; // or some fallback UI if you wish
  // }

  return (
    <div className={BannerStyles.CosmosBgMainStart}>
        <div className={BannerStyles.CosmosBgMain}>
        {/* {
            data?.file.map((item)=>{ */}
                return (
                    <>

                    <video
                    className={BannerStyles.videoBackground}
                    autoPlay
                    muted
                    loop
                    // src={`http://192.168.0.101:1041/bannerVideo/file/${item.newBannerMediaName}` }
                    src={`${process.env.REACT_APP_COSMOSINV}/videofile/file/${data.file?.newFileName}`}

                >
                   
                </video>
                
                </>
                )
            {/* })
        } */}
               
         
            
        </div>
       
    </div>
)
};

export default InvVideo;
