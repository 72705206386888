import React from 'react'
import { Typography } from '@mui/material';
import jobbnrstyles from './JobApplicationBnr.module.css'

function JobApplicationBnr({data}) {

    // console.log(data);
    return (
        <div className={jobbnrstyles.mainsec}>
            <div className={jobbnrstyles.innersec}>
                <Typography className={jobbnrstyles.Bnr_heading}>
                {data.careerJobRoleName}
                </Typography>
                <Typography className={jobbnrstyles.Bnr_para}>
                Job Type: {data.jobType}   No of Vacancies: {data.vacancy}
                </Typography>
                
            </div>
        </div>
    )
}

export default JobApplicationBnr
