import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy'
import React from 'react'
import { Helmet } from "react-helmet";
const PrivacyPage = () => {
  return (
    <>
    <Helmet>
          <html lang="en" />
          <title>Privacy </title>
          <meta name="description" content="." />
          <meta name="title" content="" />
          <meta name="keywords" content="" />

      </Helmet>
    <div>
        <NavBar/>
        <PrivacyPolicy/>
        <Footer/>
    </div>
    </>
  )
}

export default PrivacyPage