import React from 'react'
import store from './store/store'
import { Provider } from 'react-redux'
import CareerMainPage from 'components/CareerMainPage/CareerMainPage'
import ContactUsMainPage from 'components/ContactUsMainPage/ContactUsMainPage'
import CosmosMainPage from 'components/CosmosMainPage/CosmosMainPage'
// import { AllPages } from './routes/routes'
// import { MatxTheme } from 'components'
import { Outlet, useRoutes } from 'react-router-dom'
// import { AuthProvider } from 'common/contexts/JWTAuthContext'
// import { SettingsProvider } from 'common/contexts/SettingsContext'
// import Notification from './middleware/notification/Notification'
// import { ErrorBoundary } from 'react-error-boundary'
// import ErrorFallback, { myErrorHandler } from './views/sessions/Error'
import { Route, Routes } from 'react-router-dom'
import JobdescMainPage from 'components/JobdescMainPage/JobdescMainPage'
import CosmosMediaPage from 'components/CosmosMediaPage/CosmosMediaPage'
import InvestorPage from 'components/InvestorMainPage/InvestorPage'
import InvestorOppPage from 'components/InvestorOppPage/InvestorOppPage'
import JobApplicationPage from 'components/JobApplicationPage/JobApplicationPage'
import AboutUsPage from 'components/AboutUsPage/AboutUsPage'
import ComingSoonLandingPg from 'components/ComingSoonPage/ComingSoonPage'
import DisclaimerPage from 'components/DisclaimerPage/DisclaimerPage'
import PrivacyPage from 'components/PrivacyPage/PrivacyPage'
import TermsPage from 'components/TermsPage/TermsPage'
import Brochure from 'components/Brochure/Brochure'
import './views/App.css'

import { useRef, useEffect } from 'react';
import { Image } from 'cloudinary-react'
import calendarImg from '../src/assets/images/calendar.png'

import InvestmentOpportunitiesPage from 'components/InvestmentOpportunitiesPage/InvestmentOpportunitiesPage'
import EpcAggregatorPage from 'components/EpcAggregatorPage/EpcAggregatorPage'
import InvPdf from 'components/InvPdf/InvPdf'
import InvVideo from 'components/InvVideo/InvVideo'

import ScrollToTop from 'components/ScrollToTop'
import PermissionPageMain from 'components/PermissionPageMain/PermissionPageMain'
import RequestMainpage from 'components/RequestMainpage/RequestMainpage'
import CosmosNewslaterMain from 'components/CosmosNewslatter/CosmosNewslaterMain'
import ViewAllpage from 'components/MediaGallery/ViewAllPage/ViewAllpage'

const App = () => {
  // const all_pages = useRoutes(AllPages())
  // const captureRef = useRef(null);

  // const handleContextMenu = (event) => {
  //   event.preventDefault(); // Prevent the default context menu from showing
  // };

  // const handleKeyDown = (event) => {
  //   if (event.code === 'KeyI' && event.ctrlKey && event.shiftKey) {
  //     event.preventDefault(); // Prevent opening the console when pressing Ctrl + Shift + I
  //   }
  // };
  // const handleWindowKeyDown = (event) => {
  //   if (event.code === 'F12') {
  //     event.preventDefault(); // Prevent opening the console when pressing F12
  //   }
  // };

  // const handleCaptureScreenshot = () => {
  //   const captureElement = captureRef.current;
  //   captureElement.style.filter = 'brightness(0)'; // Apply black filter to the captured element

  //   html2canvas(captureElement).then((canvas) => {
  //     captureElement.style.filter = ''; // Remove the filter after capturing the screenshot
  //     const screenshot = canvas.toDataURL('image/png');
  //     // You can now use the screenshot dataURL as needed (e.g., display it, save it, etc.)
  //     console.log(screenshot);
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener('contextmenu', handleContextMenu); // Add event listener to disable right-click on the entire page
  //   window.addEventListener('keydown', handleWindowKeyDown); // Add event listener to capture keydown events on the 
  //   return () => {
  //     window.removeEventListener('contextmenu', handleContextMenu); // Remove event listener when the component unmounts
  //     window.removeEventListener('keydown', handleWindowKeyDown); // Remove event listener when the component 
  //   };
  // }, []);



  return (
    <Provider store={store}>

      <div
      //  onContextMenu={handleContextMenu} onKeyDown={handleKeyDown}
      >
        {/* <SettingsProvider> */}
        <ScrollToTop />
        <Routes
        // ref={captureRef}
        >
          {/* <SettingsProvider> */}

          <Route path="/" element={<CosmosMainPage />} />
          <Route path="/home" element={<CosmosMainPage />} />
          <Route path="/careers" element={<CareerMainPage />} />
          <Route path="/contact-us" element={<ContactUsMainPage />} />
          <Route path='/investor' element={<InvestorPage />} />
          <Route path='/investopp' element={<InvestorOppPage />} />
          <Route path="/jobapp" element={<JobApplicationPage />} />
          <Route path="/jobdesc/:id" element={<JobdescMainPage />} />
          <Route path="/media" element={<Outlet />} >
            <Route index element={<CosmosMediaPage />} />
            <Route path="newsletter/:id" element={<ViewAllpage />} />

          </Route>
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path='/comingsoon' element={<ComingSoonLandingPg />} />
          <Route path='/disclaimer' element={<DisclaimerPage />} />
          <Route path='/privacy' element={<PrivacyPage />} />
          <Route path='/terms' element={<TermsPage />} />
          <Route path='/subscribe-newsletter' element={<CosmosNewslaterMain />} />
          <Route path='/product-catalogue' element={<Brochure />} />
          <Route path='investment-opportunities/:invId/:channelId' element={<InvestmentOpportunitiesPage />} />
          <Route path='epc-aggre/:id' element={<EpcAggregatorPage />} />
          <Route path='inv-pdf/:channelId' element={<InvPdf />} />
          <Route path='inv-video/:channelId' element={<InvVideo />} />
          <Route path='permission/:invId' element={<PermissionPageMain />} />
          <Route path='request/:invId' element={<RequestMainpage />} />


        </Routes>
        {/* </SettingsProvider> */}

        <div style={{ position: 'fixed', bottom: '2rem', right: '1rem', zIndex: '999', backgroundImage: 'none', background: 'none', boxSizing: 'none', borderStyle: 'none', cursor: 'pointer', display: 'flex', gap: '10px' }}>

          {/* <a href="https://calendar.google.com/calendar" target="_blank">
            <img src={calendarImg} style={{ height: '7vh', width: '7vh', cursor: 'pointer', }} />
          </a> */}

          <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank">
            <Image style={{ height: '7vh', width: '7vh', cursor: 'pointer', }} publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680763106/whatsapp_gqgneb.png" cloudName="dxluokypg" />
          </a>


        </div>

      </div>
    </Provider>
  )
}

export default App
