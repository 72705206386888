import React from 'react'
import TOurTeam from "./InvestOurTeam.module.css"
import { Box, Typography } from '@mui/material'
import { useState, useEffect } from "react"
import axios from 'axios'



// const data = [
//     {
//         id: 1,
//         investmentHeader: 'Mr. M A',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'Worked with reputed companies like JSW, JSL, ESSAR, SMS Group, JSPL, TATA LD3, TATA.
//         Completed Capital Efficient Projects during times when there was no clarity on funds availability by finding creative solutions like execution on suppliers  credit,
//         opening usance LC, Cash management and many more.
//         Have lead projects with procurement of more than 10 Billion USD, including Basic engineering of over 100M USD.
//         More details can be discussed during the online or physical meeting.'`,
//     },
//     {
//         id: 2,
//         investmentHeader: 'Mr. N.S',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'He is a, Steel Plant Expertee ,Country Head – XX India, An Ex Executive Director Of XX, XX Joint Director, B.E., PG Diploma in Management with more than 35 years experience in Mining, Iron and Steel industry in Projects, Operations and in Leadership roles with proven success record in India and Abroad with deep knowledge of Technologies, Engineering, Finance, Commercial, HR and Allied areas. Please note they are also working in parallel and will join full time once we receive financial assistance.The Details are requested to be maintained confidential.'`,
//     },
//     {
//         id: 3,
//         investmentHeader: 'Mr. MK , P',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'KJSS Director , Is worked with Many reputed Steel Industry Like JSW, Bhushan Steel and served to the level of Vice President, Having experience in Plant Operation,
//         Project Management, Project Planning'`,
//     },
//     {
//         id: 4,
//         investmentHeader: 'Mr. N.NB',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'A graduate in Metallurgical Engineering from IIT/Kanpur. Worked for 10 years in Bhilai Steel Plant in Coke Ovens. 16 years in Durgapur Steel Plant in Coke Ovens . 4 years in Electrosteel Castings Limited . Since 2010 working in Bhushan Steel Limited . Retired as Senior Vice President, Coke Oven from Tata Steel Bhushan Steel Limited. Expertise-Concept to Commissioning of all types of Coke Ovens, Pollution control systems Concept to Commissioning of all types of Coke Ovens, Pollution control systems Material Handling, Coal Selection, Procurement & BMaterial Handling, Coal Selection, Procurement & B Commissioning Project Engineering Steel, Gas, Metallurgy, Coal, Management, Project Management, Strategic Planning, Engineering, Coking Coal Ev'`,
//     },
//     {
//         id: 5,
//         investmentHeader: 'Mr. I.S',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'IT Industry Veteran, , Business and IT leader with 30 years experience of transformation large conglomerates using cutting edge technology, business processes and Analytics. Gold Medallist Engineer from IIT Roorkee. Strong Experience of Artificial intelligence, chatbots, competitor intelligence and NLP.
//         AGM in XX LTD. A hard-core marketing professional Dealing with steel plant Equipment's, Technical sales & Marketing and have served renowned Organizations in his
//         tenure.'`,
//     },
//     {
//         id: 6,
//         investmentHeader: 'Mr. N.S',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'Experienced Director and Ecommerce Consultant with a demonstrated history of working in the Information Technology and services industry. A focussed entrepreneur Skilled in E-Commerce development, Digital Marketing, .Net, Visual Basic, Oracle Database, C++, Databases, Core Java, Angular and all other leading web technologies. Strong professional with a B.Tech focused in Information Technology from The Technological Institute of Textile & Sciences.'`,
//     },
//     {
//         id: 7,
//         investmentHeader: 'Mr. N.NB',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'Have Worked with World Renowned organization like DXX Group  Vice President ( Automation Division) XX India Pvt Ltd As (Engineering),Vice President – Projects DXX, He currently served has Managing Director xpertsforwork, His core expertise Is  Project Management , Engineering & Site Services ( Erection and Commissioning) of Blast Furnace , LD Converter, Electric Arc Furnace , Ladle Heating Furnace , RH Degasser, Slab Caster , Billet Caster, Hot Rolling Mill, Processing Lines, Bar & Rod Mill.'`,
//     },
//     {
//         id: 8,
//         investmentHeader: 'Mr. N.S',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'A steel professional with 22 years of hard-core experience in steel plant projects and maintenance with domain expertise in Steel Melting, casting & Rolling areas. Also
//         has considerable exposure to Pellet, Coke making, ASU and Lime Dolo projects.'`,
//     },
//     {
//         id: 9,
//         investmentHeader: 'Mr. N.NB',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'A Result-oriented professional with 15 years of experience in Plant Operations and Project Management. Merit of working with all leading technology supplier in the field of Iron & Steel including DRI, Blast Furnace, Electric Arc furnace, BOF Shop, Continuous Casters, Sintering & Pelletizing.'`,
//     },
//     {
//         id: 10,
//         investmentHeader: 'Mr. MK , P',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'DGM at XX LTD. He is having 20 years of experience in the areas of manufacturing industry with an extensive focus on Erection & Commissioning, Installation, Operations & Maintenance, Project Management, and Resource Optimization.'`,
//     },
//     {
//         id: 11,
//         investmentHeader: 'Mr. N.NB',
//         investmentTitle:'scscscsc',
//         investmentDetails: `'Pls note they are also working in parallel and will join full time once we receive financial assistance.The Details are requested to be maintained confidential. Currently designated as GM. He is having wide expertise of Pellet Plant, Beneficiation plant, 18MTPA Slurry pipeline, Layout optimization and optimized material movement cost while designing the concept of Integrated Steel Plant .'`,
//     },
//     {
//         id: 12,
//         investmentHeader: 'Mr. MK , P',
//         investmentDetails: `'A Result-oriented professional with 15 years of experience in Plant Operations and Project Management. Merit of working with all leading technology supplier in the field of Iron & Steel including DRI, Blast Furnace, Electric Arc furnace, BOF Shop, Continuous Casters, Sintering & Pelletizing.'`,
//     },

// ]

const InvestOurTeam = ({data}) => {

// const [data ,setData] = useState([])


// const getOurData = async () => {
//     try{
//         const res =await axios.get(
//             `${process.env.REACT_APP_COSMOSADMIN}/investoppOurTeam/getAllOurTeam`
//         )
//         setData(res.data)
//         console.log(res.data)
//     }
//     catch (error){
//         console.log(error)
//     }
// }

// useEffect(() => {
//     getOurData()
// }, [])


const getInitials=(name)=>{
    
    if(!name) return '';
    
    const names = name.split(' ');
    
    const firstNameInitial = names[0].charAt(3);
    const lastNameInitial = names[names.length - 1].charAt(0);
    
    return firstNameInitial + lastNameInitial;
}



    return (
        <div className={TOurTeam.InvestOurMain} id='tab3' >
            <div className={TOurTeam.InvestOurStart}>
                <div className={TOurTeam.TeamBox1}>
                    <Typography variant='h2'>Our Team</Typography>
                    <Typography variant='h6'>Our team is a lethal combination of industry veterans to young, dynamic, and forward-thinking individuals who are passionate about steel and its potential to transform the world. We are not bound by tradition or conventional thinking but rather embrace innovation and creativity to find new and better ways of doing things. Down the line, the Team comprises more people from different fields. The consultants on board with us are constantly working on the goal of getting business and aiding to find investors capable to invest in our unique project.</Typography>
                    <Typography variant='h6'>We request the panel of Investors to maintain the details of the consultants confidential as they are still working in parallel and will join full-time once we receive financial
                        assistance.</Typography>
                  
                </div>
                <div className={TOurTeam.TeamBox2}>
                    <div className={TOurTeam.highlight_boxes}>
                        {
                            data.map((item) => (
                                <Box className={TOurTeam.highlight_box} key={item.teamId}>
                                    <Box className={TOurTeam.highlight_boxStart}>
                                        <Box className={TOurTeam.highlight_boxCircle}>
                                            <Typography variant='h1'>{getInitials(item.teamName)}</Typography>
                                        </Box>
                                        <Box className={TOurTeam.highlight_boxName}>
                                            <Typography variant='h2' >{item.teamName}</Typography>
                                            <Typography variant='h6'>{item.teamPosition}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={TOurTeam.highlight_boxStart2}>
                                        <Typography variant='h6'> {item.teamDesc}</Typography>
                                    </Box>
                                </Box>
                            )
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestOurTeam
