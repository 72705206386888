import React from 'react'
import DescStyle from "./JabDescBg.module.css"
import { Typography } from '@mui/material'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const JabDescBg = (props) => {
  const { data,careerCategoryId} = props
  
  return (
    <div className={DescStyle.JabDescBgMain}>
      <div className={DescStyle.JabDescBgStart}>
        <Typography variant='h3'>{data.careerJobRoleName}</Typography>
        <Typography variant='h6'>Job Type: {data.jobType}  No of Vacancies: {data.vacancy}</Typography>
        <Link to='/jobapp' state={{id:data.careerJobRoleId,careerCategoryId:{careerCategoryId},data:{careerJobRoleName:data.careerJobRoleName,jobType:data.jobType,vacancy:data.vacancy}}} className={DescStyle.DescBtnStart} style={{ textDecoration: 'none'}}>
        <Button variant="contained" sx={{display:'none'}}>Apply Now</Button>
        </Link>
      </div>
    </div>
  )
}

export default JabDescBg
