import React from "react";
import { useState, useEffect } from "react"
import InvestorContent from "./ShortSummery.module.css"
import { Box, Typography, TextField } from '@mui/material'
import { Card, CardActionArea, CardContent, Grid } from '@mui/material'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios'

import { Link, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setcontactName, setcontactEmail, setcontactPhoneNumber } from './store/dataSlice';
import { chat } from './store/Chat.action'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 465,
    height: 535,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderTop: '8px solid #16469D',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const ShortSummery = ({ shortSum, card }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)


    const { invId, channelId } = useParams();
    console.log(shortSum)
    console.log(card)

    // const [info, setInfo] = useState([])
    // const [card, setCard] = useState([])

    // const SummeryData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_COSMOSADMIN}/summary&overview/getAllSummary`
    //         )
    //         setInfo(res.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // console.log(info)



    // const cardAllData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_COSMOSADMIN}/investorBusiness/getAllBusinessDetails`
    //         )
    //         setCard(res.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    // useEffect(() => {
    //     SummeryData()
    //     cardAllData()
    // }, [])








    // ----------------------------------------------------
    const dispatch = useDispatch()

    const contactState = useSelector((state) => state.chat)

    const [data, setData] = useState({
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: '',
    })



    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexphone = /^(\+|\d)[0-9]{7,16}$/

        if (!values.contactName) {
            errors.contactName = 'Name is required!'
        }
        else if (!values.contactEmail) {
            errors.contactEmail = 'Email is required!'
        }
        else if (!regexemail.test(values.contactEmail)) {
            errors.contactEmail = 'Please enter a valid email.'
        }
        if (!values.contactPhoneNumber) {
            errors.contactPhoneNumber = 'Mobile Number is required!'
        }
        else if (!regexphone.test(values.contactPhoneNumber)) {
            errors.contactPhoneNumber = 'Enter a valid phone number.'
        }

        return errors
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(data)
        setErrors(validate(data))
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            dispatch(setcontactName(data.contactName))
            dispatch(setcontactEmail(data.contactEmail))
            dispatch(setcontactPhoneNumber(data.contactPhoneNumber))
            dispatch(chat(data))
            setIsSuccess(true)
            setData({
                contactName: '',
                contactEmail: '',
                contactPhoneNumber: '',
            })
            setTimeout(() => {
                setIsSuccess(false)
            }, 2000)
        }
    }, [errors, isSubmit])

    let count = 1
    card.forEach((portal) => {
        portal.count = count
        count = count + 1
    })


    return (

        <div className={InvestorContent.InvestContentMain} id="tab1" >
            <div className={InvestorContent.InvestContentStart} >
                <Box className={InvestorContent.InvestContent2}>

                    <div className={InvestorContent.InvestContentBox1}>
                        <Typography variant='h5'>Short Summary</Typography>
                        <Typography variant='h6'>{shortSum.shortDesc}</Typography>
                        <a href="https://linktr.ee/kjsscosmos">
                            <Typography variant='h6' className={InvestorContent.linkweb}>{(shortSum.shortDesc).substr(190, 21)}</Typography>
                        </a>
                        <Typography variant='h4'>Highlights </Typography>
                        <div
                        style={{padding:"10px 20px"}}
                            dangerouslySetInnerHTML={{ __html: shortSum.highlights }} 
                        />
                        {/* <Typography variant='h6'>{shortSum.highlights} </Typography> */}

                    </div>

                    <div className={InvestorContent.InvestContentBox2}>

                        <Box className={InvestorContent.InvestContentOverview}>
                            <Typography variant='h5'>Overview</Typography>

                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>Target</Typography>
                                <Typography variant='h4'>{shortSum.target}</Typography>
                            </div>
                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>Minimum</Typography>
                                <Typography variant='h4'>{shortSum.minimum}</Typography>
                            </div>
                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>Investment Raised</Typography>
                                <Typography variant='h4'>{shortSum.investmentRaised}</Typography>
                            </div>
                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>Previous Rounds</Typography>
                                <Typography variant='h4'>{shortSum.previousRounds}</Typography>
                            </div>
                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>MVP/Finished Product</Typography>
                                <Typography variant='h4'>{shortSum.stage}</Typography>
                            </div>
                            <div className={InvestorContent.InvestContviewBox}>
                                <Typography variant='h6'>Investor Role</Typography>
                                <Typography variant='h4'>{shortSum.investorRole}</Typography>
                            </div>


                        </Box>

                    </div>
                </Box>

                <Box className={InvestorContent.BusinessStart}>
                    <Typography variant='h2'>The Business</Typography>
                    <Typography variant='h6'>We are working on the development of Steel business EPC Aggregator consultancy services and different digital platforms/web portals. these are being developed for Ist time in the world.</Typography>
                </Box>
                <div className={InvestorContent.invest_opp_content}>
                    <div className={InvestorContent.invest_opp_cards} >
                        <Grid container spacing={{ xs: 4, sm: 4, md: 3, lg: 3 ,xl:3}} sx={{ display: 'flex', flexDirection: { sm: "column", md: "row", alignItems: 'center', justifyContent: 'center' } }}>
                            {card.map((portal) =>
                                card.length <= 8 ? (
                                    <Grid  item sx={{}} key={portal.businessImageId}>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={portal.businessLink ? portal.businessLink : '/comingsoon'}
                                            target='_blank'
                                        >
                                            <Card  className={InvestorContent.investMAINCARD} sx={{ background: `linear-gradient(357.69deg, #000000 7.56%, rgba(245, 172, 56, 0) 108.45%), url(${`${process.env.REACT_APP_COSMOSINV}/investorBusiness/file/${portal.image.newBusinessImageName}`})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                <CardActionArea>

                                                    <CardContent sx={{ mt: '40%' }}>
                                                        <Typography gutterBottom variant="h6" component="div" color='#ffffff' fontWeight={450} fontSize={20}>
                                                            {portal.businessHeading}
                                                        </Typography>

                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ) : null
                            )}
                        </Grid>
                    </div>
                </div>

                <Box className={InvestorContent.investCont}>
                    <Typography variant='h5'>Got a question about this project?</Typography>
                    <Button variant="contained" onClick={handleOpen} >Chat With Us</Button>
                    <Modal className={InvestorContent.FAQModalSize}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box className={InvestorContent.FaqsModal} sx={style}>
                            <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                                <Typography id="modal-modal-title" variant="h5" sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '20px', color: '#424242', fontWeight: '600', padding: '2% 0' }}>
                                    Get in touch
                                </Typography>

                                <Typography onClick={handleClose} sx={{ textAlign: 'right', cursor: 'pointer' }}>
                                    <CancelIcon sx={{ color: 'gray' }} />
                                </Typography>
                            </div>
                            <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '19.15px', color: '#424242', fontWeight: '500', padding: '2% 0', width: '70%', }} >
                                Please submit your details so that our executive can get back to you at the earliest
                            </Typography>
                            <Box className={InvestorContent.ModalInput}>
                                <form onSubmit={handleSubmit}>

                                    <input type='Name' label="Your Name" className={InvestorContent.ContInput} placeholder='Your Name' variant='outlined' fullWidth required
                                        value={data.contactName}
                                        onChange={(e) => handleChange(e, 'contactName')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                        }} />
                                    <input type='Email' label="Your Email" className={InvestorContent.ContInput} placeholder='Your Email' variant='outlined' fullWidth required
                                        value={data.contactEmail}
                                        onChange={(e) => handleChange(e, 'contactEmail')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                        }} />
                                    <input className={InvestorContent.NumberArea} type='text' label="Phone Number" placeholder='Phone Number' variant='outlined' fullWidth required
                                        value={data.contactPhoneNumber}
                                        onChange={(e) => handleChange(e, 'contactPhoneNumber')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            fontSize: '15px',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },
                                            mb: '4%'

                                        }} />

                                    <Button variant="contained" type="submit">Submit</Button>
                                </form>
                            </Box>
                            <h1> Or </h1>
                            <div className={InvestorContent.FaqsModalButton} style={{ display: 'flex', justifyContent: 'space-between', margin: '5% 0' }}>
                                <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ width: '47%', textDecoration: 'none' }}>
                                    <Button sx={{
                                        display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#16469d', color: '#fff', border: 'none' },
                                    }}><CallOutlinedIcon className={InvestorContent.icon} />+91 93217 14104</Button></a>
                                <a href='mailto:info@kjssteel.com' style={{ width: '47%', textDecoration: 'none' }}>
                                    <Button sx={{
                                        display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#16469d', color: '#fff', border: 'none' },
                                    }}><EmailOutlinedIcon className={InvestorContent.icon} />info@kjssteel.com</Button></a>
                            </div>
                        </Box>
                    </Modal>
                </Box>
            </div>
        </div>

    )
}

export default ShortSummery
