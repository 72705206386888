import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import MissionInfoStyle from './OurMissionInfo.module.css'

const OurMissionInfo = () => {
    return (
        <div className={MissionInfoStyle.MissionInfoMain}>
            <div className={MissionInfoStyle.MissionInfoStart}>
                <Box className={MissionInfoStyle.Content}>
                    <Typography variant="h5">Our mission</Typography>
                    <Typography variant="h6">
                        Our mission is to be the vanguard of the steel industry
                        by introducing breakthrough technologies and materials
                        that transform the way we think about steel. We are
                        driven by a relentless pursuit of excellence and a
                        dedication to providing our customers with the best
                        products and services on the market. We believe in
                        operating with integrity and social responsibility, and
                        we are committed to making a positive impact on the
                        environment and the communities we serve.
                    </Typography>
                </Box>
                <Box className={MissionInfoStyle.Content}>
                    <Typography variant="h5">Our vision</Typography>
                    <Typography variant="h6">
                        Our vision is to create a world where steel is not just
                        a commodity, but a catalyst for progress and growth. We
                        see a future where steel plays a vital role in driving
                        innovation, creating jobs, and empowering communities.
                        Through our unwavering commitment to excellence,
                        sustainability, and social responsibility, we aim to
                        shape that future and make a lasting impact on the
                        world.
                    </Typography>
                </Box>
            </div>
        </div>
    )
}

export default OurMissionInfo
