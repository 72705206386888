import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import JabDescBg from 'components/JabDescBg/JabDescBg'
import JobDescInformation from 'components/JobDescInformation/JobDescInformation'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
const JobdescMainPage = () => {

  const location = useLocation()
  const data = location.state.job;
  const careerCategoryId= location.state.careerCategoryId;
  

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

 
  return (
    <>
      <Helmet>
                <html lang="en" />
                <title>Job Description | KJSS COSMOS</title>
                <meta name="description" content="" />
                <meta name="title" content="" />
                <meta name="keywords" content="" />

            </Helmet>
    <div 
    onContextMenu={handleContextMenu}
    >
      <NavBar />
      <JabDescBg data={data} />
      <JobDescInformation data={data} careerCategoryId={careerCategoryId}  />
      <Footer/>
    </div>
    </>
  )
}

export default JobdescMainPage
