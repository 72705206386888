import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import JobApplicationBnr from 'components/JobApplicationBnr/JobApplicationBnr'
import JobApplicationForm from 'components/JobApplicationForm/JobApplicationForm'
import {useLocation} from 'react-router-dom'
import { Helmet } from "react-helmet";
import React from 'react'

function JobApplicationPage() {
  const {state}=useLocation();
    
    const {data}=state;
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
  

  return (
    <>
     <Helmet>
                <html lang="en" />
                <title>Job Applications | KJSS COSMOS</title>
                <meta name="description" content="" />
                <meta name="title" content="" />
                <meta name="keywords" content="" />

            </Helmet>
    <div 
    onContextMenu={handleContextMenu}
    >
      <NavBar />
      <JobApplicationBnr data={data} />
      <JobApplicationForm />
      <Footer />
    </div>
    </>
  )
}

export default JobApplicationPage
