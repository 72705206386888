import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import { Box, Typography } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionStyles from './ContactUsAccordion.module.css'
import { useState, useEffect } from "react"
import axios from 'axios'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';


function ContactUsAccordion() {
    
    const [data, setData] = useState([])
    const [expanded, setExpanded] = React.useState([]);

    const addressData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/address/getAllAddress`
            )
            setData(res.data)
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    console.log(data)
    useEffect(() => {
        addressData()
    }, [])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded((prevExpanded) => {
          if (isExpanded) {
            return [...prevExpanded, panel];
          } else {
            return prevExpanded.filter((p) => p !== panel);
          }
        });
    };
    

    return (
        <div  className={AccordionStyles.AccordionMainSection}>
            <Box className={AccordionStyles.AccordionMain}>
                <Typography variant='h5'>Branch Offices</Typography>
             {data.map((item) =>
                <Accordion disableGutters={true} expanded={expanded.includes(`panel${item.addressId}`)} onChange={handleChange(`panel${item.addressId}`)} sx={{ boxShadow:'0.2px 0.2px 2px 0.2px gray' ,bgcolor: '#F8F8F8', borderBottom: '1px solid #ddddd'}}>
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={item.id + '-content'}
                        id={item.id + '-header'}
                    >
                       <Typography  className={AccordionStyles.AccordionSection} >{item.branchAddressName}</Typography>
                    </AccordionSummary>
                   
                        <AccordionDetails >
                            <Typography  className={AccordionStyles.AccordionAddress}>{item.branchAddressDesc}</Typography>
                            <Typography className={AccordionStyles.AccordionContact}><LocalPhoneOutlinedIcon />{item.contactNo}</Typography>
                        </AccordionDetails>
                  
                </Accordion>
            )}
            </Box>
        </div>
    );
}
export default ContactUsAccordion