import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import BannerStyles from './CosmosBanner.module.css'
import { useState, useEffect } from 'react'
import axios from 'axios'

const CosmosBanner = () => {
    // GET with Axios

    const [dataVideo, setDataVideo] = useState([])

    const getTeamData = async() => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/bannerVideo/getAllBannerDetails`
            )
             setDataVideo(res.data)
           
            console.log(res.data)
            
        } catch (error) {
            console.log(error+"   check ")
        }
    }

    useEffect(() => {
        getTeamData()
    }, [])

    return (
        <div className={BannerStyles.CosmosBgMainStart}>
            <div className={BannerStyles.CosmosBgMain}>
            {
               
                dataVideo?.map((item)=>{
                    return (
                        <>

                        <video
                        className={BannerStyles.videoBackground}
                        autoPlay
                        muted
                        loop
                        // src={`http://192.168.0.101:1041/bannerVideo/file/${item.newBannerMediaName}` }
                        src={`${process.env.REACT_APP_COSMOSADMIN}/bannerVideo/file/${item?.newBannerMediaName}`}

                    >
                       
                    </video>
                    <Box className={BannerStyles.CosmosBgContent}>
                    <Typography
                        variant="h5"
                        sx={{
                            display: 'flex',
                            fontWeight: '510',
                            fontSize: '20px',
                            color: '#fff',
                        }}
                    >
                        {item?.bannerVideo?.bannerTitle}
                    </Typography>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            fontWeight: '700',
                            fontSize: '80px',
                            lineHeight: '88px',
                            color: '#fff',
                        }}
                    >
                        {item?.bannerVideo?.bannerHeading}
                    </Typography>
                </Box>
                    </>
                    )
                })
            }
                   
             
                
            </div>
            <div className={BannerStyles.CosmosBgMain2}>
                <Box className={BannerStyles.CosmosBgContent}>
                    <Typography variant="h5"
                        sx={{
                            display: 'flex',
                            fontWeight: '510',
                            fontSize: '20px',
                            color: '#fff',
                        }}
                    >
                        THE NEW FRONTIER OF GROWTH
                    </Typography>
                    <Typography variant="h1"
                        sx={{
                            display: 'flex',
                            fontWeight: '700',
                            fontSize: '80px',
                            lineHeight: '88px',
                            color: '#fff',
                        }}
                    >
                        Knowing Judging Sourcing
                    </Typography>
                </Box>
            </div>
        </div>
    )
}

export default CosmosBanner
