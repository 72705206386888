import React from 'react'
import Request from 'components/Request/Request'

import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
const RequestMainpage = () => {
    return (
        <div>
            <NavBar />
            <Request />
            <Footer />
        </div>
    )
}

export default RequestMainpage
