import React from 'react'
import JobInfoStyle from "./JobDescInformation.module.css"
import { Box, Typography } from '@mui/material'
import { Image } from 'cloudinary-react'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'




const JobDescInformation = (props) => {

    const { data, careerCategoryId } = props


    return (
        <div className={JobInfoStyle.JobDescInformationMain}>
            <div className={JobInfoStyle.JobDescInformationStart}>
                <Box className={JobInfoStyle.JobDescBoxFrist}>
                    <Box className={JobInfoStyle.boxgap}>
                        <label>Who Are We Looking For</label>
                        <div
                        // className={JobInfoStyle}
                        style={{padding:"10px 20px"}}
                            dangerouslySetInnerHTML={{ __html: data.whoAreWeLookingFor }} // Set the HTML content as a string
                        />
                        {/* <Typography variant='h4'>  {data.whoAreWeLookingFor}</Typography> */}
                    </Box>
                    <Box className={JobInfoStyle.boxgap}>
                        <label>What You Will Be Doing</label>
                        <div
                        style={{padding:"10px 20px"}}
                            dangerouslySetInnerHTML={{ __html: data.whatYouWillBeDoing }} // Set the HTML content as a string
                        />
                        {/* <Typography variant='h4'> {data.whatYouWillBeDoing}</Typography> */}
                    </Box>
                    <Box className={JobInfoStyle.boxgap}>
                        <label>Educational Requirement</label>
                        <Typography variant='h4'> {data.educationalRequirement}</Typography>
                    </Box>
                    <Box className={JobInfoStyle.boxgap}>
                        <label>Remuneration</label>
                        <Typography variant='h4'> {data.remuneration}</Typography>
                    </Box>
                </Box>
                <Box className={JobInfoStyle.JobDescInformationMiddle}>
                    <Box className={JobInfoStyle.JobDescBoxSecond}>
                        <Box className={JobInfoStyle.JobContain}>
                            <label>Job Summary</label>
                            <Box className={JobInfoStyle.Boxess}>
                                <Typography variant='h3'>Location</Typography>
                                <Typography variant='h6'>{data.jobLocation}</Typography>
                            </Box>
                            <Box className={JobInfoStyle.Boxess}>
                                <Typography variant='h3'>Job Type</Typography>
                                <Typography variant='h6'>{data.jobType}</Typography>
                            </Box>
                            <Box className={JobInfoStyle.Boxess}>
                                <Typography variant='h3'>Date posted</Typography>
                                <Typography variant='h6'>{data.careerJobRoleDate}</Typography>
                            </Box>
                            <Box className={JobInfoStyle.Boxess}>
                                <Typography variant='h3'>Experience</Typography>
                                <Typography variant='h6'>{data.careerJobRoleExperience}</Typography>
                            </Box>
                            <Box className={JobInfoStyle.Boxess}>
                                <Typography variant='h3'>Vacancy</Typography>
                                <Typography variant='h6'>{data.vacancy}</Typography>
                            </Box>
                            <Box><Link to='/careers'>View all job</Link></Box>
                        </Box>
                    </Box>
                    <Box className={JobInfoStyle.JobDescBoxThird}>
                        <Box className={JobInfoStyle.BoxessDown}>
                            <Typography variant='h6'>Share this:</Typography>

                            <Box className={JobInfoStyle.SocialIcons}>
                                <a href="https://www.facebook.com/profile.php?id=100086595487175" target="_blank">
                                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581658/Facebook_d6ws5h.svg" cloudName="dxluokypg" className={JobInfoStyle.icon} />
                                </a>
                                <a href="https://twitter.com/KjssCosmos" target="_blank">
                                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Twitter_ujajbb.svg" cloudName="dxluokypg" className={JobInfoStyle.icon} />
                                </a>
                                <a href="https://www.linkedin.com/in/kjss-cosmos-pvt-ltd-056613258/" target="_blank">
                                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/LinkedIn_qyemfg.svg" cloudName="dxluokypg" className={JobInfoStyle.icon} />
                                </a>
                                <a href="https://www.instagram.com/pr.kjsscosmospvtltd/" target="_blank">
                                    <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Instagram_cebpv7.svg" cloudName="dxluokypg" className={JobInfoStyle.icon} />
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
            <div className={JobInfoStyle.DescDown}>
                <Link to="/jobapp" state={{ id: data.careerJobRoleId, careerCategoryId: { careerCategoryId }, data: { careerJobRoleName: data.careerJobRoleName, jobType: data.jobType, vacancy: data.vacancy } }} className={JobInfoStyle.DescBtnStart} style={{ textDecoration: 'none' }}>
                    <Button variant="contained">Apply Now</Button>
                </Link>

            </div>
        </div>
    )
}

export default JobDescInformation
