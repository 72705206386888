import React from 'react'
import Flipbook from './Brochure.module.css'


const Brochure = () => {
  return (
    <div className={Flipbook.Brochuremain}>
    <iframe allowfullscreen="allowfullscreen"  className="fp-iframe" src="https://heyzine.com/flip-book/aeb4828a59.html"
     style={{border:' 1px solid lightgray', width:' 100%', height: '100vh'}}></iframe>
    </div>
  )
}

export default Brochure


