import React, { useState } from 'react'
import jobFormstyles from './JobApplicationForm.module.css'
import { Typography, TextField, Button, Box } from '@mui/material'
import { InputLabel } from '@mui/material'
import { useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

function JobApplicationForm() {
    const { state } = useLocation();

    const { id, careerCategoryId } = state;
    const jobRoleID = id;

    console.log(state);
    // const [data, setData] = useState([])
    const [file, setFile] = useState(null)
    const [formData, setFormData] = useState({
        applicantName: '',
        applicantEmail: '',
        applicantContact: '',
        // applicantPosition: '',
        applicantTotalExperience: '',
        applicantCurrentCtc: '',
        applicantExpectedCtc: '',
        applicantSkills: '',
        applicantCoverLetter: ''
    })
    const [errors, setErrors] = useState({})

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexphone = /^(\+|\d)[0-9]{7,16}$/
        if (!values.applicantName) {
            errors.applicantName = 'Name is required!'
        } else if (values.applicantName.trim().length === 0) {
            errors.applicantName = 'Enter a valid name.';
        } else if (values.applicantName.trim().length === 1) {
            errors.applicantName = 'Enter a valid name with more than one letter.';
        } 
        if (!values.applicantEmail) {
            errors.applicantEmail = 'Email is required!'
        } else if (!regexemail.test(values.applicantEmail)) {
            errors.applicantEmail = 'Please enter a valid email.'
        } 
         if (!values.applicantContact) {
            errors.applicantContact = 'Mobile Number is required!'
        } else if (!regexphone.test(values.applicantContact)) {
            errors.applicantContact = 'Enter a valid phone number.'
        } 
        if (!values.applicantTotalExperience) {
            errors.applicantTotalExperience = `Please enter your experience in years.`
        } 
        if (!values.applicantCurrentCtc) {
            errors.applicantCurrentCtc = `Please enter your current CTC.`
        } 
        if (!values.applicantExpectedCtc) {
            errors.applicantExpectedCtc = `Please enter your expected CTC.`
        } 
         if (!values.applicantSkills) {
            errors.applicantSkills = `Please enter your skills.`
        } 
         if (!values.applicantCoverLetter) {
            errors.applicantCoverLetter = `Please add your cover letter.`
        }
        return errors
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        console.log(file)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(validate(formData))
        // Create form data object
        if (Object.keys(errors).length === 0) {
            const formDataObj = new FormData();
            formDataObj.append("file", file);
            formDataObj.append("applicantName", formData.applicantName);
            formDataObj.append("applicantEmail", formData.applicantEmail);
            formDataObj.append("applicantContact", formData.applicantContact);
            // formDataObj.append("applicantPosition", formData.applicantPosition);
            formDataObj.append("applicantTotalExperience", formData.applicantTotalExperience);
            formDataObj.append("applicantCurrentCtc", formData.applicantCurrentCtc);
            formDataObj.append("applicantExpectedCtc", formData.applicantExpectedCtc);
            formDataObj.append("applicantSkills", formData.applicantSkills);
            formDataObj.append("applicantCoverLetter", formData.applicantCoverLetter);
            console.log(formDataObj);
            try {
                // Make a POST request to upload the resume
                const resumeResponse = await axios.post(
                    `${process.env.REACT_APP_COSMOSMAIN}/career/addResume/${careerCategoryId.careerCategoryId}/${jobRoleID}`,

                    formDataObj,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(resumeResponse)

                // If resume upload is successful, make a POST request to submit the form data
                const applicationResponse = await axios.post(
                    `${process.env.REACT_APP_COSMOSMAIN}/career/addApplication/${careerCategoryId.careerCategoryId}/${jobRoleID}/${resumeResponse.data.resumeId}`,

                    formData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                console.log(applicationResponse)

                console.log("Job application submitted successfully");
                alert('Job application submitted successfully.')
                setFile(null)
                setFormData({
                    applicantName: '',
                    applicantEmail: '',
                    applicantContact: '',
                    // applicantPosition: '',
                    applicantTotalExperience: '',
                    applicantCurrentCtc: '',
                    applicantExpectedCtc: '',
                    applicantSkills: '',
                    applicantCoverLetter: ''
                })
            } catch (error) {
                console.error(error);
            }
        }
    };


    return (
        <div className={jobFormstyles.mainsec}>
            <div className={jobFormstyles.innersec}>
                <Typography className={jobFormstyles.Form_heading}>
                    Application Form
                </Typography>
                <form action="" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className={jobFormstyles.form_sec} >
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Full name*"
                            variant="standard"
                            name="applicantName"
                            value={formData.applicantName}
                            onChange={handleInputChange} 
                        />
                        {errors.applicantName ? (
                            <Typography
                                variant="caption"
                                sx={{ 
    
                                    textAlign: 'left',
                                    color: 'red',
                                    
                                    // width: '100%'
                                }}
                            >
                                {errors.applicantName}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Email*"
                            variant="standard"
                            name='applicantEmail'
                            value={formData.applicantEmail}
                            onChange={handleInputChange}
                        />
                        {errors.applicantEmail ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red', 
                                }}
                            >
                                {errors.applicantEmail}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Contact Number*"
                            variant="standard"
                            name='applicantContact'
                            value={formData.applicantContact}
                            onChange={handleInputChange}
                        />
                        {errors.applicantContact ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantContact}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Total Experience in years*"
                            variant="standard"
                            name='applicantTotalExperience'
                            value={formData.applicantTotalExperience}
                            onChange={handleInputChange}
                        />
                        {errors.applicantTotalExperience ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantTotalExperience}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Current CTC*"
                            variant="standard"
                            name='applicantCurrentCtc'
                            value={formData.applicantCurrentCtc}
                            onChange={handleInputChange}
                        />
                        {errors.applicantCurrentCtc ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantCurrentCtc}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Expected CTC*"
                            variant="standard"
                            name='applicantExpectedCtc'
                            value={formData.applicantExpectedCtc}
                            onChange={handleInputChange}
                        />
                        {errors.applicantExpectedCtc ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantExpectedCtc}
                            </Typography>
                        ) : null}
                        <TextField
                            className={jobFormstyles.form_inputtxt}
                            label="Skills*"
                            variant="standard"
                            name='applicantSkills'
                            value={formData.applicantSkills}
                            onChange={handleInputChange}
                        />
                        {errors.applicantSkills ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantSkills}
                            </Typography>
                        ) : null}
                        <InputLabel
                            className={jobFormstyles.form_inputtxt1}
                            sx={{ pb: 0 }}
                            style={{paddingBottom: '5px'}}
                        >
                            Upload Resume*
                        </InputLabel>


                        <div className={jobFormstyles.fileinputcontainer}>
                            <div className={jobFormstyles.filename} id="file-name">{file?.name}</div>
                            <label className={jobFormstyles.custombutton}>
                                <span className={jobFormstyles.custombuttonlabel}>Browse</span>
                                <input style={{ cursor: 'pointer' }} type="file" name='resume' onChange={handleFileInputChange} />
                            </label>
                        </div>
                        



                        <div className={jobFormstyles.ImgInput_footer}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            </div>
                        </div>

                        <Typography className={jobFormstyles.doctxt}>Only: doc/ docx/ pdf / 1mb</Typography>
                   
                   
                        <div className={jobFormstyles.lastsection}>
                        <InputLabel style={{paddingBottom: '5px'}} className={jobFormstyles.form_inputtxt1}>Cover Letter*</InputLabel>
                        <textarea
                            className={jobFormstyles.coverltrtxt}
                            multiline
                            rows={3}
                            maxRows={5}
                            columns={5}
                            name='applicantCoverLetter'
                            value={formData.applicantCoverLetter}
                            onChange={handleInputChange}
                        />
                        {errors.applicantCoverLetter ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: '300',
                                    textAlign: 'left',
                                    color: 'red',
                                    // width: '100%'
                                    marginBottom: '4px',
                                }}
                            >
                                {errors.applicantCoverLetter}
                            </Typography>
                        ) : null}
                        <Button
                            variant="contained"
                            size="medium"
                            className={jobFormstyles.formbtn}
                            type='submit'
                        >
                            Submit & Apply
                        </Button>
                    </div>
                   
                    </div>

                </form>
            </div>

        </div>
    )
}

export default JobApplicationForm
