import React from 'react'
import MissionStyle from './CosmosOurMission.module.css'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger'
import { useEffect } from 'react';
import { useState } from 'react';



const CosmosOurMission = () => {
    const [counterOn, setCounterOn] = useState(false)

    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className={MissionStyle.MissionMain}>
                <Box className={MissionStyle.MissionContent}>
                    <Box>
                        <Typography variant='h2'>
                            {counterOn && <CountUp start={0} end={2} duration={3} delay={0} />}
                            <span>K+</span>
                        </Typography>

                        <Typography variant='h6'>Partners</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h2'>
                            {counterOn && <CountUp start={0} end={17} duration={3} delay={0} />}
                            <span>+</span>
                        </Typography>

                        <Typography variant='h6'>Countries</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h2'>
                            {counterOn && <CountUp start={0} end={3} duration={3} delay={0} />}
                            <span>K+</span>
                        </Typography>

                        <Typography variant='h6'>Products</Typography>
                    </Box>
                </Box>
            </div>
        </ScrollTrigger>
    )
}

export default CosmosOurMission
