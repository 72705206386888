import CompanyDetails from 'components/CompanyDetails/CompanyDetails'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import InvestHighlight from 'components/InvestHighlight/InvestHighlight'
import InvestorBanner from 'components/InvestorBanner/InvestorBanner'
import React from 'react'
import { Helmet } from "react-helmet";
const InvestorPage = () => {

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title> investor | kjss Cosmos </title>
        <meta name="description" content="We provide investor's for conceiving steel plant, a product-catalogue, and assistance on sourcing steel specific to their needs." />
        <meta name="title" content="investor | kjss Cosmos" />
        <meta name="keywords" content="" />

      </Helmet>
      <div 
      onContextMenu={handleContextMenu}
      >
        <NavBar />
        <InvestorBanner />
        <InvestHighlight />
        <CompanyDetails />
        <Footer />
      </div>
    </>
  )
}

export default InvestorPage