import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import TermsService from 'components/TermsService/TermsService'
import React from 'react'
import { Helmet } from "react-helmet";
const TermsPage = () => {
  return (
    <>
    <Helmet>
          <html lang="en" />
          <title>Terms</title>
          <meta name="description" content="." />
          <meta name="title" content="" />
          <meta name="keywords" content="" />

      </Helmet>
    <div>
        <NavBar/>
        <TermsService/>
        <Footer/>
    </div>
    </>
  )
}

export default TermsPage