import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import NewsStyle from './CosmosNews.module.css'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CosmosNews = () => {
    const [articles, setArticles] = useState([])


    const baseLink = 'https://newz.kjssteel.com/'; // Base URL

    const getArticles = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_COSMOSADMIN}/news/scrape?url=https://newz.kjssteel.com/news/`)
            console.log(res.data)
            setArticles(res.data)
        } catch (error) {
            console.log("error occured")
        }



    }

    useEffect(() => {
        getArticles()
    }, [])


    // const Articles=()=>{
    //     if(articles!==null)
    //     {
    //         for(let i=1;i<=3;i++)
    //         {
    //             return(
    //                 <a href="https://newz.kjssteel.com//news" className={NewsStyle.Info} target='_blank' style={{ textDecoration: 'none' }}>
    //                 <Typography variant='h6'>{articles[1].date}</Typography>
    //                 <Typography variant='h4'>{articles[1].title}</Typography>
    //                 <Typography variant='h5'>{articles.content}</Typography>
    //                 </a>
    //             )
    //         }
    //     }

    // }
    return (
        <div className={NewsStyle.NewsMain}>
            <div className={NewsStyle.NewsStart}>
                <a href={baseLink + articles[0]?.readMoreLink} className={NewsStyle.ContentBG} target='_blank' style={{ textDecoration: 'none' }}>
                    
                    <Typography variant='h3'>Latest news</Typography>
                    <img src='https://res.cloudinary.com/dxluokypg/image/upload/v1680166362/newsBG_nnj84w.svg'></img>
                    {articles.length !== 0 &&
                        <>
                            <Typography variant="h6">{articles[0].date}</Typography>
                            <Typography variant="h4">{articles[0].title}</Typography>
                            <Typography
                                variant="h5"
                                className={`${NewsStyle.lineclamp} ${NewsStyle.newsContent}`}
                                dangerouslySetInnerHTML={{
                                    __html: articles[0].content.length > 200 // Check if the content is longer than 200 characters
                                        ? articles[0].content.substring(0, 300) + "..." // Display the first 200 characters followed by an ellipsis
                                        : articles[0].content // Display the full content if it's 200 characters or less
                                }}
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "300",
                                    color: "#585858",
                                    // textAlign: 'justify'
                                }}
                            ></Typography>

                        </>

                    }
                </a>
                <Box className={NewsStyle.ContentInfo}>
                    <Box className={NewsStyle.ContentInfoStart}>
                        {/* {articles && console.log(articles)} */}
                        {
                            articles.length >= 1

                                ? articles.slice(1, 4).map((data) => {

                                    return (
                                        <a
                                            href={baseLink + data.readMoreLink}
                                            className={NewsStyle.Info}
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Typography variant="h6">{data.date}</Typography>
                                            <Typography variant="h4">{data.title}</Typography>
                                            {/* <div className={NewsStyle.lineclamp} >{data.content}</div> */}
                                            <p
                                                className={`${NewsStyle.lineclamp} ${NewsStyle.newsContent}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: data.content.length > 200 // Check if the content is longer than 200 characters
                                                        ? data.content.substring(0, 200) + "..." // Display the first 200 characters followed by an ellipsis
                                                        : data.content // Display the full content if it's 200 characters or less
                                                }}
                                                style={{
                                                    fontSize: "15px",
                                                    fontWeight: "300",
                                                    color: "#585858",
                                                    textAlign: 'justify'
                                                }}
                                            ></p>

                                        </a>
                                    );
                                })
                                :
                                <>
                                    <div className={NewsStyle.placeholderNewsParent} >


                                        {Array.from({ length: 3 }).map((_, index) => (
                                            <div key={index} className={NewsStyle.placeholderNewsDiv}>
                                                <div className={NewsStyle.placeholderNewsHead}></div>
                                                <div className={NewsStyle.placeholderNewssubHead}></div>
                                                <p className={NewsStyle.placeholderNewsPara}></p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                        }

                        {/* <a href="https://newz.kjssteel.com//article/favourable-market-trends-for-the-steel-industry" className={NewsStyle.Info} target='_blank' style={{ textDecoration: 'none',paddingTop: '5%' }}>
                                <Typography variant='h6'>May 11, 2018</Typography>
                                <Typography variant='h4'>Favourable Market Trends For The Steel Industry</Typography>
                                <Typography variant='h5'>There has been a ray of hope, in the steel industry as market forces steer it away from the horrible abyss of recession, gradually. Several factors are contributing...</Typography>
                        </a>
                        <a href="http://information.khuljasimsim.in/article/factors-affecting-coke-rate-in-a-blast-furnace" className={NewsStyle.Info} target='_blank' style={{ textDecoration: 'none',paddingTop: '5%' }}>
                                <Typography variant='h6'>January 17, 2018</Typography>
                                <Typography variant='h4'>Factors affecting coke rate in a blast furnace</Typography>
                                <Typography variant='h5'>A blast furnace (BF) is a multi variable system which is subjected to a large number of inter-influencing variables affecting its performance...</Typography>
                        </a> */}
                    </Box>
                </Box>
                <Box className={NewsStyle.ContentImg}>
                    <Box className={NewsStyle.ImgBox}>
                        <Typography variant='h5'>JSPL will invest Rs 7,930 crore under PLI scheme for specialty steel, says MD Bimlendra Jha</Typography>
                        <Link to='https://newz.kjssteel.com/' target="_blank" style={{ textDecoration: 'none', width: '55%' }} >
                            <Button variant="contained">Know More</Button>
                        </Link>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

export default CosmosNews
