import React from 'react'
import InvestorBan from './InvestmentOpportunitiesBg.module.css'
import { Typography } from '@mui/material'


const InvestmentOpportunitiesBg = () => {
  return (
    <div>
          <div className={InvestorBan.bannerStart}>
            <div className={InvestorBan.banner1}>
                <Typography variant='h2'>EPC Aggregator</Typography>
            </div>
        </div>
    </div>
  )
}

export default InvestmentOpportunitiesBg
