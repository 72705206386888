import { createSlice } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        name: '',
        email: '',
        mobileNumber: '',
        companyName: '',
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        setname: (state, action) => {
            state.name = action.payload
        },
        setemail: (state, action) => {
            state.email = action.payload
        },
        setmobileNumber: (state, action) => {
            state.mobileNumber = action.payload
        },
        setcompanyName: (state, action) => {
            state.companyName = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
    }
})

export const {
    setname,
    setemail,
    setcompanyName,
    setmobileNumber,
    setLoading,
    setError,
    setSuccess,
} = permissionSlice.actions

export default permissionSlice.reducer