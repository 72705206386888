import React from 'react'
import InvestmentOpportunitiesBg from 'components/InvestmentOpportunitiesBg/InvestmentOpportunitiesBg'
import TheMarket from 'components/TheMarket/TheMarket'
import InvestOurTeam from 'components/InvestOurTeam/InvestOurTeam'
import Document from 'components/Document/Document'
import ShortSummery from 'components/ShortSummery/ShortSummery'
import InvSlideBar from 'components/InvSlideBar/InvSlideBar'
import NavBar from 'components/Header/NavBar'
import Footer from 'components/Footer/Footer'
import axios from 'axios'
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';



const InvestmentOpportunitiesPage = () => {
  const { channelId } = useParams();


  const [data, setData] = useState(null);
  // const [invData, setInvData] = useState([])

  const allData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_COSMOSINV}/channel/getChannelById/${channelId}`
      )
      setData(res.data)
      console.log(res.data);
    } catch (error) {

      console.log(error)
    }
  }
  useEffect(() => {

    allData()
  }, [])


  return (
    <div >
      <NavBar />
      <InvestmentOpportunitiesBg />
      {
        data &&
        <>
          <InvSlideBar data={data} />
          <ShortSummery shortSum={data.summaryoverview} card={data.bussiness} />
          <TheMarket mark={data.graphs} />
          <InvestOurTeam data={data.team} />
          <Document data={data.docs} />

        </>

      }


      <Footer />
    </div>
  )
}

export default InvestmentOpportunitiesPage
