import { Box, Typography } from '@mui/material'
import React from 'react'
import Styles from './css/OurBenefits.module.css'
import { Image } from 'cloudinary-react'

function OurBenefits() {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box className={Styles.OurBenefits}>
                <Box className={Styles.fitsStart} >
                    <Typography variant='h3'>Benefits</Typography>
                    <Typography variant='h1'>Why you Should Join Our Awesome Team</Typography>
                    <Typography variant='h6'>As a Start-up company, we are looking for individuals who are willing to take on challenges, work collaboratively with a team, and demonstrate a willingness to learn and grow.</Typography>
                </Box>
                <Box className={Styles.fitsStartCont} sx={{
                    width: { xs: '100%', sm: '100%', md: '100%', lg: '85%' },
                    display: { xs: 'block', sm: 'flex', md: 'flex', lg: 'flex' },
                    flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'wrap' },
                    textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
                    justifyContent: { md: 'space-between' },

                }}>
                    <Box sx={{
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '45%' },
                        textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
                        p: '5%',
                    }}>
                        <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683197186/Career_1_y3xcqc.svg" cloudName="dxluokypg" style={{ padding: '13px', backgroundColor: '#EFF2F5', borderRadius: '4px' }} />

                        <Typography variant='h4'
                            sx={{
                                width:'100%',
                                fontWeight: '700',
                                color: '#585858',
                                fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '18px' },
                                lineHeight: { xs: '12px', sm: '18px', md: '19px', lg: '21px' },
                                mt: { xs: '10%', sm: '10%', md: '5%', lg: '5%' },
                                mb: '5%',

                            }}>Growth Opportunity</Typography>
                        <Typography variant='h6'
                            sx={{
                                fontWeight: '300',
                                color: '#585858',
                                fontSize: { xs: '12px', sm: '13px', md: '14px', lg: '14px' },
                                lineHeight: { xs: '19px', sm: '18px', md: '19px', lg: '24px' },
                            }}>Joining our team will allow to expand your skills and work on exciting and dynamic projects.
                        </Typography>
                    </Box >
                    <Box sx={{
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '45%' },
                        textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
                        p: '5%'
                    }}
                    >
                        <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683197586/Career_2_iy3qs4.svg" cloudName="dxluokypg" style={{ padding: '18px', backgroundColor: '#FFEEED', borderRadius: '4px' }} />

                        <Typography variant='h4'
                            sx={{
                                width:'100%',
                                fontWeight: '700',
                                color: '#585858',
                                fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '18px' },
                                lineHeight: { xs: '12px', sm: '18px', md: '19px', lg: '21px' },
                                mt: { xs: '10%', sm: '10%', md: '5%', lg: '5%' },
                                mb: '5%',
                            }}>Equity & ownership</Typography>
                        <Typography variant='h6'
                            sx={{
                                fontWeight: '300',
                                color: '#585858',
                                fontSize: { xs: '12px', sm: '13px', md: '14px', lg: '14px' },
                                lineHeight: { xs: '19px', sm: '18px', md: '19px', lg: '24px' },
                            }}>Equity and ownership options will be provided to employees as significant financial rewards and security for their future once the company is successfull.</Typography>
                    </Box>
                    <Box sx={{
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '45%' },
                        textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
                        p: '5%'
                    }}>
                        <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683197594/Career_3_kkgope.svg" cloudName="dxluokypg" style={{ padding: '18px', backgroundColor: '#F1F7E8', borderRadius: '4px' }} />

                        <Typography variant='h4'
                            sx={{
                                width:'100%',
                                fontWeight: '700',
                                color: '#585858',
                                fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '18px' },
                                lineHeight: { xs: '12px', sm: '18px', md: '19px', lg: '21px' },
                                mt: { xs: '10%', sm: '10%', md: '5%', lg: '6%' },
                                mb: '6%',
                            }}>Creative freedom</Typography>
                        <Typography variant='h6'
                            sx={{
                                fontWeight: '300',
                                color: '#585858',
                                fontSize: { xs: '12px', sm: '13px', md: '14px', lg: '14px' },
                                lineHeight: { xs: '19px', sm: '18px', md: '19px', lg: '24px' },
                            }}>It takes creativity and innovation to find solutions to unprecedented problems. We are open to flexible work arrangements to liberate our employees for a better work-life balance.</Typography>
                    </Box >
                    <Box sx={{
                        p: '5%',
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '45%' },
                        textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' }
                    }}
                    >
                        <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683197595/Career_4_tw2tdc.svg" cloudName="dxluokypg" style={{ padding: '18px', backgroundColor: '#E7F2FF', borderRadius: '4px' }} />

                        <Typography variant='h4'
                            sx={{
                                width:'100%',
                                fontWeight: '700',
                                color: '#585858',
                                fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '18px' },
                                lineHeight: { xs: '12px', sm: '18px', md: '19px', lg: '21px' },
                                mt: { xs: '10%', sm: '10%', md: '5%', lg: '5%' },
                                mb: '5%',
                            }}>Team work</Typography>
                        <Typography variant='h6'
                            sx={{
                                fontWeight: '300',
                                color: '#585858',
                                fontSize: { xs: '12px', sm: '13px', md: '14px', lg: '14px' },
                                lineHeight: { xs: '19px', sm: '18px', md: '19px', lg: '24px' },
                            }}>Being a startup we have smaller teams and a more collaborative environment, which can foster a sense of community and teamwork among employees. </Typography>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default OurBenefits