import React, { useState } from 'react'
import OurStoryStyles from './AboutPgOurStorySec.module.css'
import { Box, Typography } from '@mui/material'
import CountUp from 'react-countup';

import ScrollTrigger from 'react-scroll-trigger';

function AboutPgOurStorySec() {
    const [counterOn, setCounterOn] = useState(false)

    return (
        <div className={OurStoryStyles.OurStoryMainSec}>
            <div className={OurStoryStyles.OurStoryInnerSec}>
    
                <Box className={OurStoryStyles.StoryBnrPara}>
                    <Typography variant='h2'>Our journey begins...</Typography>
                    <Typography variant='h6'>
                    We are a start-up on a mission to create a difference in the steel industry. Our team is a lethal combination of industry veterans and young, dynamic, and forward-thinking individuals who are passionate about steel and its potential to transform the world. We are not bound by tradition or conventional thinking, but rather embrace innovation and creativity to find new and better ways of doing things. We believe that the industry is ripe for innovation and that there is a huge opportunity to create products and services that meet the needs of modern society. Our goal is to be at the forefront of this change by developing new technologies, materials, and processes that push the boundaries of what is possible. We are not just a steel company; we are a partner in innovation.
                    </Typography>
                </Box>

                <Box className={OurStoryStyles.StoryImgSec}>
                    <Box className={OurStoryStyles.StoryBoxSecPara}>
                        <Typography variant='h5'>
                        At our core, we are a customer-centric company. We understand that our success depends on the satisfaction of our customers, and we are committed to delivering exceptional service at every stage of the customer journey. From product selection and development to delivery and after-sales support, our team of experts is constantly pushing the boundaries of what is possible.
                        </Typography>
                    </Box>
                </Box>
                <Box className={OurStoryStyles.StoryImgSecHide}>
                        <Typography variant='h5'>
                        At our core, we are a customer-centric company. We understand that our success depends on the satisfaction of our customers, and we are committed to delivering exceptional service at every stage of the customer journey. From product selection and development to delivery and after-sales support, our team of experts is constantly pushing the boundaries of what is possible.
                        </Typography>
                </Box>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className={OurStoryStyles.CounterSection}>

                        <Box className={OurStoryStyles.countersecBox1}>
                            <Typography className={OurStoryStyles.countersecBoxPara}>
                                As a start-up, we are nimble and agile, with the
                                ability to quickly adapt to changing market
                                conditions and customer needs. We are not
                                burdened by bureaucracy or red tape, but rather
                                have the flexibility to make decisions quickly
                                and effectively. This allows us to stay ahead of
                                the competition and provide our customers with
                                the most innovative and cutting-edge solutions
                                available.
                            </Typography>
                        </Box>
                        <Box className={OurStoryStyles.countersecBox2}>
                            <Box className={OurStoryStyles.count0}>
                                <Typography className={OurStoryStyles.countertxt}>
                                    {counterOn && <CountUp start={0} end={2} duration={6} delay={0} />}
                                    <span>K+</span>
                                </Typography>
                                <Typography variant='h4'>Partners</Typography>
                            </Box>
                            <Box className={OurStoryStyles.count0}>
                                <Typography className={OurStoryStyles.countertxt}>
                                    {counterOn && <CountUp start={0} end={17} duration={6} delay={0} />}
                                    <span>+</span>
                                </Typography>
                                <Typography variant='h4'>Countries</Typography>
                            </Box>

                            <Box className={OurStoryStyles.count0}>
                                <Typography className={OurStoryStyles.countertxt}>
                                    {counterOn && <CountUp start={0} end={3} duration={6} delay={0} />}
                                    <span>K+</span>
                                </Typography>
                                <Typography variant='h4'>Products</Typography>
                            </Box>
                        </Box>
                    </div>
                </ScrollTrigger>
            </div>
        </div>
    )
}

export default AboutPgOurStorySec
