import React, { useState } from 'react'
import Newsletterstyle from './CosmosNewslatter.module.css'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import InputUnstyled from '@mui/base/InputUnstyled'
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import jsonp from 'jsonp'
import { Link } from 'react-router-dom';




const CosmosNewslatter = () => {



 
    return (
        <div className={Newsletterstyle.AboutUsPageNewletterMain}>
            <div className={Newsletterstyle.AboutUsPageNewletter}>
                <div className={Newsletterstyle.AboutUsPageNewletterBox1}>
      
                        <Typography variant="h4">
                            Subscribe Our Newsletter
                        </Typography>
                        <Typography variant="h6">
                            Stay in touch with us to get latest news.
                        </Typography>

                </div>
                <div className={Newsletterstyle.AboutUsPageNewletterBox2}>
                    {/* <Box className={Newsletterstyle.BtnStart}> */}
                       
                        <Link to="/subscribe-newsletter" >
                        <Button 
                        
                        sx={{textTransform: 'capitalize', fontSize: '20px', fontWeight: '500', borderRadius: '4px 4px 4px 4px',}}
                        //  onClick={handleSubmit} 
                         variant="contained">Subscribe</Button>
                        </Link>
                    {/* </Box> */}
                  
                </div>
            </div>
        </div>
    )
}

export default CosmosNewslatter
