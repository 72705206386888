import AboutPgOurStorySec from 'components/AboutPgOurStorySec/AboutPgOurStorySec'
import AboutUsBanner from 'components/AboutUsBanner/AboutUsBanner'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import React from 'react'
import AboutSustnableDelpSec from 'components/AboutSustnableDelpSec/AboutSustnableDelpSec'
import { Helmet } from "react-helmet";
function AboutUsPage() {

    const handleContextMenu = (e) => {
        e.preventDefault();
      };

    return (
        <>
         <Helmet>
                <html lang="en" />
                <title>About-us | Conceiving Steel Plant | Project Management  </title>
                <meta name="description" content="We are engaged in providing top-notch services for knowing judging, sourcing, conceiving steel plants." />
                <meta name="title" content="About-us | Conceiving Steel Plant | Project Management" />
                <meta name="keywords" content="" />

            </Helmet>
        <div 
        onContextMenu={handleContextMenu}
        >
            <NavBar/>
            <AboutUsBanner />
            <AboutPgOurStorySec />
            <AboutSustnableDelpSec />
            <Footer/>
        </div>
        </>
    )
}

export default AboutUsPage
