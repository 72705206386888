import React, { useState } from 'react';
// import "./scss/AllRegistration.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import subscribNewsLatter from './CosmosNewslaterMain.module.css'

const data = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518380/Mockup_1_1_vilx0a.svg',
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    }
];

const CosmosNewslaterMain = () => {
    var settings = {
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    };
    const navigate = useNavigate();


    return (
        <div className={subscribNewsLatter.USerLOginMAin}>
            <div className={subscribNewsLatter.USerLOginStart} style={{
                width: '100%', // Set width to 100% for responsiveness
                height: '100vh', // Set height to 100% of the viewport height
                display:'flex'
            }}
            >
                <Box className={subscribNewsLatter.USerLOginBox}>
                    {/* <div style={{ width: '100%' }}> */}
                        <iframe
                            // width={"50%"} height="100%"
                           

                            title="Newsletter Subscription Form"
                            src="https://a3d54e1c.sibforms.com/serve/MUIFABg4ixx6fA33qpS4IoK_8cmTjQ4HgodEMwMnaUgJD1sCmNja1pwq8kT5zBSodFqTkfS-x8PhztrWJFpBxzUnuPyACNPFroi-2GGbC0BtLYJ4HwGKZ2gRF2HqJAgohNzOayLTS-0ILfNO4HI5ftTWp7cTxOHIrSr8ovF2XujXqeXnCB81PsnxUuBp69NRxnFziPSaQRIg9iEC"
                            frameBorder="0"
                            scrolling="no"
                            style={{
                                width: '1200px', // Full-width
                                height: '100vh', // Set the height as needed
                               
                                display:'flex',justifyContent:'center',
                                alignItems:'center'
                            }}
                        />
                    {/* </div> */}
                </Box>
                <Box className={subscribNewsLatter.USerLOginBox2} sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex'
                }}>
                    <Box className={subscribNewsLatter.USerBoxSTart2}>
                        <Slider {...settings}>
                            {data.map((item) => (
                                <div className={subscribNewsLatter.USerMEdiaBox2} key={item.id}>
                                    <img src={item.linkImg} alt='Computer' />
                                </div>
                            ))}
                        </Slider>
                    </Box>
                    <div className={subscribNewsLatter.USerBOxInfo}>
                        <Typography variant='h4' style={{ marginBottom: "1.5%" }}>Join the Steel Revolution!</Typography>
                        <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default CosmosNewslaterMain;
