import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import MediaGallery from 'components/MediaGallery/MediaGallery'
import MediaPageBackground from 'components/MediaPageBackground/MediaPageBackground'
import NewsArticle from 'components/NewsArticle/NewsArticle'
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
const CosmosMediaPage = () => {

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
          <Helmet>
                <html lang="en" />
                <title>Media | KJSS COSMOS</title>
                <meta name="description" content="" />
                <meta name="title" content="" />
                <meta name="keywords" content="" />

            </Helmet>
    <div 
    onContextMenu={handleContextMenu}
    >
      <NavBar />
      <MediaPageBackground />
      <MediaGallery />
      <NewsArticle />
      <Footer />
    </div>
    </>
  )
}

export default CosmosMediaPage
