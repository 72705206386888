import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import React, { useEffect, useState } from 'react'
import GalleryStyle from '../MediaGallery.module.css'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



const ViewAllpage = () => {

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');

    const getGalleryData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_COSMOSADMIN}/meta/getAllArticles/${id}`)
            setData(res.data);

            if (res.data.length > 0) {
                setSubCategory(res.data[0].subCategory);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getGalleryData();
    }, [])


    const sortByDate = (order) => {
        const sortedData = [...data].sort((a, b) => {
            if (order === 'desc') {
                return new Date(b.createdOn) - new Date(a.createdOn);
            } else {
                return new Date(a.createdOn) - new Date(b.createdOn);
            }
        });
        setData(sortedData);
    };
    const handleSortOrderChange = () => {
        const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        setSortOrder(newSortOrder);
        sortByDate(newSortOrder);
    };


    
    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100dvh', justifyContent: 'space-between' }}>
            <NavBar />

            <div className={GalleryStyle.MediaGalleryMain}>
                <div className={GalleryStyle.MediaGalleryStart}>

                    <div className={GalleryStyle.BoxStart}>
                        <div className={GalleryStyle.header} >
                            <p>{subCategory}</p>
                            <div onClick={handleSortOrderChange}  className={GalleryStyle.filter} >
                                {sortOrder === 'desc' ? <p>Newest <ArrowDownwardIcon /> </p> : <p>Oldest <ArrowUpwardIcon />  </p>}
                            </div>
                        </div>
                        <div className={GalleryStyle.GridContainer}>
                            {data.map((contentItem, contentIndex) => (
                                <Link to={contentItem.link} target="_blank" key={contentIndex} className={GalleryStyle.MediaBox}>
                                    <div className={GalleryStyle.MediaImg}>
                                        <img src={contentItem.url} alt='ergrgtr' />
                                    </div>
                                    <div className={GalleryStyle.imgInfo}>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>{contentItem?.createdOn?.split("T")[0]} </span>
                                            <Link to={contentItem.link} target="_blank"> <ArrowOutwardIcon /></Link>
                                        </div>
                                        <h4>{contentItem.title}.</h4>
                                        <p>{contentItem?.description?.length > 120 ? `${contentItem?.description?.slice(0, 120)}...` : contentItem?.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>


                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default ViewAllpage
