import React from 'react'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import ContactUsForm from 'components/ContactUsForm/ContactUsForm'
import ContactUsBannerPage from 'components/ContactUsBaneerPage/ContactUsBannerPage'
import ContactUsAccordion from 'components/ContactUsAccordion/ContactUsAccordion'
import { Helmet } from "react-helmet";

function ContactUsMainPage() {
  
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
                <html lang="en" />
                <title>Contact us | kjss cosmos</title>
                <meta name="description" content="Kjss Cosmos is your one-stop destination for sourcing quality steel and providing the best services. Feel free to contact us for any of your steel sourcing needs." />
                <meta name="title" content="Contact us | kjss cosmos" />
                <meta name="keywords" content="" />

            </Helmet>
    <div 
    onContextMenu={handleContextMenu}
    >
      <NavBar />
      <ContactUsBannerPage />
      <ContactUsForm />
      <ContactUsAccordion />
      <Footer />
    </div>
    </>
  )
}

export default ContactUsMainPage