import React, { useState, useEffect } from 'react'
import Slide from './InvSlideBar.module.css'
import { Box, Typography, TextField } from '@mui/material'
// import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { animateScroll as scroll, scroller } from 'react-scroll';

import { useDispatch, useSelector } from 'react-redux';
import { setcontactName, setcontactEmail, setcontactPhoneNumber } from './store/dataSlice';
import { interested } from './store/Interested.action';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 465,
    height: 600,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderTop: '8px solid #16469D',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const InvSlideBar = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleScroll = (tabIndex) => {
        setValue(tabIndex.toString());
        scroller.scrollTo(`tab${tabIndex}`, {
            smooth: true,
            duration: 400,
            delay: 40,
            offset: -100
        })
    }


    // ---------------------------------------------------------

    const dispatch = useDispatch()

    const contactState = useSelector((state) => state.interested)

    const [data, setData] = useState({
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange1 = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexphone = /^(\+|\d)[0-9]{7,16}$/

        if (!values.contactName) {
            errors.contactName = 'Name is required!'
        }
        else if (!values.contactEmail) {
            errors.contactEmail = 'Email is required!'
        }
        else if (!regexemail.test(values.contactEmail)) {
            errors.contactEmail = 'Please enter a valid email.'
        }
        if (!values.contactPhoneNumber) {
            errors.contactPhoneNumber = 'Mobile Number is required!'
        }
        else if (!regexphone.test(values.contactPhoneNumber)) {
            errors.contactPhoneNumber = 'Enter a valid phone number.'
        }

        return errors
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(data)
        setErrors(validate(data))
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            dispatch(setcontactName(data.contactName))
            dispatch(setcontactEmail(data.contactEmail))
            dispatch(setcontactPhoneNumber(data.contactPhoneNumber))
            dispatch(interested(data))
            setIsSuccess(true)
            setData({
                contactName: '',
                contactEmail: '',
                contactPhoneNumber: '',
            })
            setTimeout(() => {
                setIsSuccess(false)
            }, 2000)
        }
    }, [errors, isSubmit])

    return (
        <div className={Slide.InvSlideMain}>
            <Box className={Slide.InvSlideStart}>
                <TabContext value={value}
                    variant="fullWidth"
                    TabIndicatorProps={{ children: <span style={{ maxWidth: 40, color: '#16469d' }} /> }}
                    centered>

                    <Box className={Slide.listBar} >
                        <TabList className={Slide.listStart} onChange={handleChange} aria-label="lab API tabs example" TabIndicatorProps={{
                            sx: { height: 4, width: '10% !important' }

                        }}>
                            <Tab className={Slide.list} label="Overview" value="1" onClick={() => handleScroll("1")} />
                            <Tab className={Slide.list} label="Pitch Details" value="2" onClick={() => handleScroll("2")} />
                            <Tab className={Slide.list} label="The Team" value="3" onClick={() => handleScroll("3")} />
                            <Tab className={Slide.list} label="Documents" value="4" onClick={() => handleScroll("4")} />
                            <Box className={Slide.listButton}>
                                <Button variant="contained" onClick={handleOpen}>I’m Interested</Button>
                                <Modal className={Slide.FAQModalSize}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"

                                >
                                    <Box className={Slide.FaqsModal} sx={style}>
                                        <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                                            <Typography id="modal-modal-title" variant="h5" sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '20px', color: '#424242', fontWeight: '600', padding: '2% 0' }}>
                                                Get in touch
                                            </Typography>
                                            <Typography onClick={handleClose} sx={{ textAlign: 'right', cursor: 'pointer' }}>
                                                <CancelIcon sx={{ color: 'gray' }} />
                                            </Typography>


                                        </div>
                                        <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '19.15px', color: '#424242', fontWeight: '500', padding: '2% 0', width: '70%', }} >
                                            Please submit your details so that our executive can get back to you at the earliest
                                        </Typography>
                                        <Box className={Slide.ModalInput}>
                                            <form onSubmit={handleSubmit}>

                                                <TextField type='Email' label="Your Email" className={Slide.ContInput} placeholder='Your Email' variant='outlined' fullWidth required
                                                    value={data.contactEmail}
                                                    onChange={(e) => handleChange1(e, 'contactEmail')}
                                                    sx={{
                                                        bgcolor: '#FFFFFF',
                                                        color: '#232536',
                                                        fontWeight: '400',
                                                        lineHeight: '28px',
                                                        mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                                    }} />

                                                <TextField className={Slide.NumberArea} type='text' label="Phone Number" placeholder='Phone Number' variant='outlined' fullWidth required
                                                    value={data.contactPhoneNumber}
                                                    onChange={(e) => handleChange1(e, 'contactPhoneNumber')}
                                                    sx={{
                                                        bgcolor: '#FFFFFF',
                                                        color: '#232536',
                                                        fontWeight: '400',
                                                        fontSize: '15px',
                                                        lineHeight: '28px',
                                                        mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },
                                                        mb: '4%'

                                                    }} />

                                                <Button variant="contained" type="submit">Submit</Button>
                                            </form>
                                        </Box>
                                        <h1> Or </h1>
                                        <div className={Slide.FaqsModalButton} style={{ display: 'flex', justifyContent: 'space-between', margin: '5% 0' }}>
                                            <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ width: '47%', textDecoration: 'none' }}>
                                                <Button sx={{
                                                    display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#3578DE', color: '#fff', border: 'none' },
                                                }}><CallOutlinedIcon />+91 93217 14104</Button></a>
                                            <a href='mailto:info@kjssteel.com' style={{ width: '47%', textDecoration: 'none' }}>
                                                <Button sx={{
                                                    display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#3578DE', color: '#fff', border: 'none' },
                                                }}><EmailOutlinedIcon />info@kjssteel.com</Button></a>
                                        </div>
                                    </Box>
                                </Modal>
                            </Box>
                        </TabList>
                    </Box>
                </TabContext>
                <Box className={Slide.listButtonHide}>
                    <Button variant="contained" onClick={handleOpen}>I’m Interested</Button>
                    <Modal className={Slide.FAQModalSize}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box className={Slide.FaqsModal} sx={style}>
                            <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                                <Typography id="modal-modal-title" variant="h5" sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '20px', color: '#424242', fontWeight: '600', padding: '2% 0' }}>
                                    Get in touch
                                </Typography>
                                <Typography onClick={handleClose} sx={{ textAlign: 'right', cursor: 'pointer' }}>
                                    <CancelIcon sx={{ color: 'gray' }} />
                                </Typography>


                            </div>
                            <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '19.15px', color: '#424242', fontWeight: '500', padding: '2% 0', width: '70%', }} >
                                Please submit your details so that our executive can get back to you at the earliest
                            </Typography>
                            <Box className={Slide.ModalInput}>
                                <form onSubmit={handleSubmit}>
                                    <TextField type='Name' label="Your Name" className={Slide.ContInput} placeholder='Your Name' variant='outlined' fullWidth required
                                        value={data.contactName}
                                        onChange={(e) => handleChange1(e, 'contactName')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                        }} />

                                    <TextField type='Email' label="Your Email" className={Slide.ContInput} placeholder='Your Email' variant='outlined' fullWidth required
                                        value={data.contactEmail}
                                        onChange={(e) => handleChange1(e, 'contactEmail')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                        }} />
                                    <TextField className={Slide.NumberArea} type='text' label="Phone Number" placeholder='Phone Number' variant='outlined' fullWidth required
                                        value={data.contactPhoneNumber}
                                        onChange={(e) => handleChange1(e, 'contactPhoneNumber')}
                                        sx={{
                                            bgcolor: '#FFFFFF',
                                            color: '#232536',
                                            fontWeight: '400',
                                            fontSize: '15px',
                                            lineHeight: '28px',
                                            mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },
                                            mb: '4%'

                                        }} />

                                    <Button variant="contained" type="submit">Submit</Button>
                                </form>
                            </Box>
                            <h1> Or </h1>
                            <div className={Slide.FaqsModalButton} style={{ display: 'flex', justifyContent: 'space-between', margin: '5% 0' }}>
                                <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ width: '47%', textDecoration: 'none' }}>
                                    <Button sx={{
                                        display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#3578DE', color: '#fff', border: 'none' },
                                    }}><CallOutlinedIcon className={Slide.icon} />+91 93217 14104</Button></a>
                                <a href='mailto:info@kjssteel.com' style={{ width: '47%', textDecoration: 'none' }}>
                                    <Button sx={{
                                        display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#3578DE', color: '#fff', border: 'none' },
                                    }}><EmailOutlinedIcon className={Slide.icon} />info@kjssteel.com</Button></a>
                            </div>
                        </Box>
                    </Modal>
                </Box>
            </Box>
        </div>

    )
}

export default InvSlideBar
