import React, { useEffect, useState } from 'react'
import CompDetStyles from './CompanyDetails.module.css'
import { Box, Typography } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios';


const CompanyDetails = () => {

    const [data, setData] = useState([])
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');

    const getCompanyDetails = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/companydetails/getAllCompanyDetails`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePdfLink = async (detail) => {
        setSelectedCompanyDetail(detail);
        setPdfUrl('');
        if (detail && detail.pdfId) {
            const pdfId = detail.pdfId;
            const apiUrl = `${process.env.REACT_APP_COSMOSADMIN}/companydetails/file/${detail.pdfFile}`;

            axios.get(apiUrl, {
                responseType: 'blob' // set the response type to blob
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading PDF file', error);
                });
        }

    }

    useEffect(() => {
        getCompanyDetails()
    }, [])

    return (
        <div className={CompDetStyles.companyDetails}>
            <div className={CompDetStyles.companyDetails_content}>
                <Typography variant='h2' sx={{
                    fontSize: '30px',
                    fontWeight: '600',
                    color: ' #585858',
                    marginTop: '2%'
                }}>
                    Company details
                </Typography>
                {data?.map((detail) => (
                    <Box className={CompDetStyles.companyDetail}>
                        <Typography className={CompDetStyles.detailName}>
                            {detail?.companyDetails?.companyDetailsHeading}
                        </Typography>
                        <a 
                        // onClick={() => handlePdfLink(detail)} 
                        href={`${process.env.REACT_APP_COSMOSADMIN}/companydetails/file/${detail.pdfFile}`}
                        target='_blank' className={CompDetStyles.detailLink}>
                            <FileDownloadOutlinedIcon className={CompDetStyles.detailLinkicon} fontSize='large' sx={{ color: '#585858', cursor: 'pointer' }} />
                        </a>
                    </Box>
                ))}
            </div>
        </div>
    )
}

export default CompanyDetails