import React from 'react'
import MediaStyle from "./MediaPageBackground.module.css"
import { Box, Typography } from '@mui/material'

const MediaPageBackground = () => {
  return (
    <div className={MediaStyle.MediaBGMain}>
      <Box className={MediaStyle.MediaBGStart}>
        <Typography variant='h2'>Media</Typography>
      </Box>
    </div>
  )
}

export default MediaPageBackground
