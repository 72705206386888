import EpcAggregatorBg from 'components/EpcAggregatorBg/EpcAggregatorBg'
import EpcAggregatorCard from 'components/EpcAggregatorCard/EpcAggregatorCard'
import NavBar from 'components/Header/NavBar'
import Footer from 'components/Footer/Footer'
import React from 'react'

const EpcAggregator = () => {
  return (
    <div>
      <NavBar/>
      <EpcAggregatorBg/>
      <EpcAggregatorCard/>
      <Footer/>
    </div>
  )
}

export default EpcAggregator
