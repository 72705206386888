import React, { useState, useEffect } from 'react'
import Teamstyle1 from '../MeetOurTeam/MeetOurTeamSection.module.css'
import Box from '@mui/material/Box'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Modal from '@mui/material/Modal'
import { Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import axios from 'axios'
import { Translate } from '@mui/icons-material'
// import { useState, useEffect } from 'react'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderTop: '8px solid #16469D',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
}



function MyModal(props) {
    const { open, handleClose, data } = props
    console.log(data)
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ width: "100%", zIndex: '99999999' }}>

            <Box className={Teamstyle1.modalStyle}>
                <Typography
                    onClick={handleClose}
                    sx={{ textAlign: 'right', cursor: 'pointer' }}>
                    <CancelIcon sx={{ color: 'gray' }} />
                </Typography>
                <div className={Teamstyle1.modalStart}>

                    <img src={`${process.env.REACT_APP_COSMOSADMIN}/team/file/${data.newteamImageName}`}
                        style={{ borderRadius: '4px' }} />

                    <div className={Teamstyle1.profileDescritpion}>
                        <Typography
                            sx={{
                                fontWeight: '700',
                                fontSize: '30px',
                                color: '#16469D',
                            }}
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {data.team?.teammateName}
                        </Typography>

                        <Typography
                            className='teammateDescri'
                            sx={{
                                fontWeight: '500',
                                fontSize: '20px',
                                color: '#16469D',

                            }}
                            id="modal-modal-description">

                            {data.team?.teammatePosition}
                        </Typography>

                        <div className='reactQuilllist'
                            style={{
                                fontSize: '15px', fontWeight: '400',
                                color: '#000', lineHeight: '24px',
                                overflowY: 'auto', height: '100%',
                                textAlign: 'justify'
                            }}
                            id="modal-modal-description"
                            dangerouslySetInnerHTML={{ __html: data.team?.teammateInfo }} >
                        </div>

                        {/* <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '15px',
                                color: '#000000',
                                lineHeight: '24px',
                                overflowY: "auto",
                                height: "100%",
                                textAlign: 'justify',
                            }}
                            id="modal-modal-description">
                            {data.team?.teammateInfo}
                        </Typography> */}
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

function MeetOurTeamSection() {


    // GET with Axios

    const [data, setData] = useState([])

    const getTeamData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/team/getAllTeamDetails`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTeamData()
    }, [])



    ////////////////csss//////////

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        // lazyLoad: false,
        nextArrow: false,
        prevArrow: false,

        swipeToSlide: true,

        responsive: [

            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 1,
                },
            },


            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },

        ],
    }

    const [open, setOpen] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const handleOpen = (data) => {
        setSelectedData(data)
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    return (
        <div className={Teamstyle1.container} id='team'>
            <Box className={Teamstyle1.TeamContent}>
                <div className={Teamstyle1.heading}>Meet our team of Experts</div>
                <Slider {...settings} style={{ height: "100%" }}>
                    {data?.map((member) => (
                        <Box className={Teamstyle1.card1}
                            onClick={() => handleOpen(member)}
                            key={member?.teammateId}
                        >
                            <div className={Teamstyle1.cardTop}>
                                <img
                                    src={`${process.env.REACT_APP_COSMOSADMIN}/team/file/${member?.newteamImageName}`}
                                    alt={member?.team?.teammateName}
                                />
                            </div>

                            <div className={Teamstyle1.cardBottom}>
                                <div className={Teamstyle1.TeamHead}>
                                    {member?.team?.teammateName}
                                </div>

                                <div className={Teamstyle1.Teampara}>
                                    {member?.team?.teammatePosition}
                                </div>
                            </div>
                        </Box>
                    ))}
                </Slider>

                <MyModal
                    open={open}
                    handleClose={handleClose}
                    data={selectedData}
                />
            </Box>
        </div>
    )
}

export default MeetOurTeamSection
