import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabsStyle from "./CareerTabs.module.css"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Button from '@mui/material/Button';

// const categories = [
//     {
//         id: 0,
//         categoryName: 'HR & Admin'
//     },
//     {
//         id: 1,
//         categoryName: 'Engineering'
//     },
//     {
//         id: 2,
//         categoryName: 'Support'
//     },
//     {
//         id: 3,
//         categoryName: 'Design'
//     },
//     {
//         id: 4,
//         categoryName: 'Digital Marketing'
//     }
// ]

// const jobs = [
//     {
//         id: 0,
//         categoryId: 0,
//         jobName: 'Javascript Developer',
//         experience: '2 years',
//         deadline: '2022-04-12'
//     },
//     {
//         id: 1,
//         categoryId: 0,
//         jobName: 'Apps Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 2,
//         categoryId: 1,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 3,
//         categoryId: 1,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 4,
//         categoryId: 2,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 5,
//         categoryId: 2,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 6,
//         categoryId: 3,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 7,
//         categoryId: 3,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 8,
//         categoryId: 4,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     },
//     {
//         id: 9,
//         categoryId: 4,
//         jobName: 'Javascript Developer',
//         experience: '3 years',
//         deadline: '2022-04-18'
//     }
// ]

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `vertical-tab-${index}`,
//         'aria-controls': `vertical-tabpanel-${index}`,

//     };
// }

export default function CareerTabs() {
    const [data, setData] = useState([])
    const [jobs, setJobs] = useState([])
    const [tabSelected,setTabSelected]=useState("");
    const getCategoryData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/career/getAllCareerOpenings`
            )
            setData(res.data)
            if(res.data)
            {
                setTabSelected(res.data[0].careerCategoryId)
                handleDeptSelect(res.data[0].careerCategoryId)
            }
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCategoryData()
    }, [])

    const handleDeptSelect = (id) => {

        setTabSelected(id);
        if (data) {
            const selectedJob = data.find(job => job.careerCategoryId === id);

            setJobs(selectedJob.jobrole);

        }



    }
    // const [value, setValue] = React.useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    // const categorizedJobs = categories.map(category => ({
    //     ...category,
    //     jobs: jobs.filter(job => job.categoryId === category.id)
    // }))

    return (
        <div className={TabsStyle.MainCareer}>
            <div className={TabsStyle.MainCareerHidebar}>
                <div className={TabsStyle.FirstCareerTab} >
                    <Box className={TabsStyle.Careerheads}>
                        <Typography variant='h4'>Come join us</Typography>
                        <Typography variant='h2'>Career Openings</Typography>
                        <Typography variant='h6'>We’re always looking for creative, talented self-starters to join the KJSS
                            family. Check out our open roles below and fill out an application.</Typography>
                    </Box>
                    <Box className={TabsStyle.Careerheads2} >
                        <Typography variant='h4'>join us</Typography>
                        <Typography variant='h6'>Are you looking to build a career with a start-up company? If so, then you're in the right place! </Typography>

                    </Box>
                </div>

                <div className={TabsStyle.rJobContainerMain}>
                    <div className={TabsStyle.rJobContainer}>
                        <div className={TabsStyle.rSideBar}>
                            <h4>Departments</h4>
                            <ul className={TabsStyle.rDeptList}>
                                {data.map((dept) => {
                                    return (
                                        <li key={`Department ${dept.careerCategoryId}`} className={tabSelected===dept.careerCategoryId ?`${TabsStyle.rDeptListItem} ${TabsStyle.rDeptListItemSelected}`:`${TabsStyle.rDeptListItem}` }
                                            onClick={() => handleDeptSelect(dept.careerCategoryId)}
                                        >
                                            {dept.careerCategoryName}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className={TabsStyle.rJobPannel}>
                           
                            {
                                jobs.length == 0
                                    ? 
                                    <div className={TabsStyle.rNoJob}>
                                        Currently No Jobs
                                    </div>
                                    :
                                    <>
                                    {
                                        jobs.map((job)=>{
                                            return(
                                                <Link to={`/jobdesc/${job?.careerJobRoleId}`} state={{job:job,careerCategoryId:tabSelected}} className={TabsStyle.rJobRow}>
                                                    <div className={TabsStyle.rJobRowInner}>
                                                <h5>{job.careerJobRoleName}</h5>
                                                <div className={TabsStyle.rJobColumn} >
                                                    <p>Experience:</p>
                                                    <h5>{job.careerJobRoleExperience}</h5>
                                                </div>
                                                <div className={`${TabsStyle.rJobColumn} ${TabsStyle.mHide}`} >
                                                    <p >Deadline:</p>
                                                    <h5 >{job.careerJobRoleDate}</h5>
                                                </div>
                                                </div>
                                                <div className={TabsStyle.rJobbtn}>
                                                    <span>Apply Now</span>
                                                </div>
                                                </Link>
                                        )
                                        })
                                    }
                                   
                                    </>
                                        
                            }
                        </div>
                    </div>
                </div>

              

            </div>

        </div>
    )
}

