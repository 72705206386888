import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
    name: 'market',
    initialState: {
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: '',
        loading: false,
        error: null,
        success: false,
    },
    reducers: {

        setcontactName: (state, action) => {
            state.contactName = action.payload
        },

        setcontactEmail: (state, action) => {
            state.contactEmail = action.payload
        },
        setcontactPhoneNumber: (state, action) => {
            state.contactPhoneNumber = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
    }
})

export const {
    setcontactName,
    setcontactEmail,
    setcontactPhoneNumber,
    setLoading,
    setError,
    setSuccess,
} = contactSlice.actions

export default contactSlice.reducer