import PermissionPage from 'components/PermissionPage/PermissionPage'
import React from 'react'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'

const PermissionPageMain = () => {
    return (
        <div>
            <NavBar />
            <PermissionPage />
            <Footer />
        </div>
    )
}

export default PermissionPageMain
