import React from 'react'
import { Typography } from '@mui/material';
import abtbnrstyles from './AboutUsBanner.module.css'

function AboutUsBanner() {
  return (
    <div className={abtbnrstyles.AbtMainSec}>
      <div className={abtbnrstyles.AbtInnerSec}>
        <Typography className={abtbnrstyles.AbtBnrHeading}>
          About us
        </Typography>
      </div>
    </div>
  )
}

export default AboutUsBanner
