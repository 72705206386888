import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import NewsArticleStyle from "./NewsArticle.module.css"
import axios from 'axios'


const handleDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return (<><p>{day}</p><span>{`${month}, ${year}`}</span></>)
}
const ArticleWork = ({ eventName, eventDesc, eventDate, eventLink }) => {
    return (
        <div className={NewsArticleStyle.SingleBoxCon}>
            <a href={eventLink} target='_blank' style={{ textDecoration: 'none' }}>
                <div className={NewsArticleStyle.ArtContentStart}>
                    <Box className={NewsArticleStyle.ArticleBox1}>
                        <Typography variant='h4'>{eventName}</Typography>
                        <p>{eventDesc.substr(0, 50) + '...'}</p>
                    </Box>

                    <div className={NewsArticleStyle.dateDiv} >{handleDate(eventDate)}</div>

                </div>
            </a>
        </div>
    )
}


const baseLink = 'https://newz.kjssteel.com/'; // Base URL
const formatDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Use JavaScript's Date methods to format the date as needed
    const formattedDate = dateObject.toLocaleDateString(); // This will give you the date in "MM/DD/YYYY" or "DD/MM/YYYY" format
    return formattedDate;
}


const NewsArticle = () => {

    const [data, setData] = useState([])

    const getArticleData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_COSMOSADMIN}/upcomingevents/getAllUpcomingEvents`)

            const currentDate = new Date();
            // Filter and set the upcoming events with dates greater than or equal to the current date
            const upcomingEvents = res.data.filter(event => new Date(event.eventDate) >= currentDate);
            setData(res.data)
            setData(upcomingEvents);
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getArticleData()
    }, [])

    // API Key: 88c25e539a5d4500bc16c368838b6d9a

    const [articles, setArticles] = useState([])

    const getArticles = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_COSMOSADMIN}/news/scrape?url=https://newz.kjssteel.com/news/`)
            console.log(res.data)
            setArticles(res.data)
        } catch (error) {
            console.log("error occured")
        }



    }

    useEffect(() => {
        getArticles()
    }, [])
    return (
        <div className={NewsArticleStyle.NewsMain}>
            <div className={NewsArticleStyle.NewsStart}>
                <a href={baseLink + articles[0]?.readMoreLink}  target='_blank' className={NewsArticleStyle.ContentBGMain} style={{ textDecoration: 'none' }}>
                    <Box className={NewsArticleStyle.ContentBG}>
                        <Typography variant='h1'>News Articles</Typography>
                        <img src='https://res.cloudinary.com/dxluokypg/image/upload/v1680166362/newsBG_nnj84w.svg'></img>
                        {articles.length !== 0 &&
                            <>
                                <Typography variant="h6">{formatDateString(articles[0].date)}</Typography>
                                <Typography variant="h4">{articles[0].title}</Typography>
                                <Typography
                                    variant="h5"
                                    className={`${NewsArticleStyle.lineclamp} ${NewsArticleStyle.newsContent}`}
                                    dangerouslySetInnerHTML={{
                                        __html: articles[0].content.length > 200 // Check if the content is longer than 200 characters
                                            ? articles[0].content.substring(0, 200) + "..." // Display the first 200 characters followed by an ellipsis
                                            : articles[0].content // Display the full content if it's 200 characters or less
                                    }}
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "300",
                                        color: "#585858"
                                    }}
                                ></Typography>


                            </>
                        } </Box>
                </a>
                <Box className={NewsArticleStyle.ContentInfo}>
                    <Box className={NewsArticleStyle.ContentInfoStart}>
                        {articles.length >= 1

                            ? articles.slice(1, 4).map((data) => {

                                return (
                                    <a
                                        href={baseLink + data.readMoreLink}
                                        className={NewsArticleStyle.Info}
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography variant="h6">{formatDateString(data.date)}</Typography>
                                        <Typography variant="h4">{data.title}</Typography>
                                        <p
                                            className={`${NewsArticleStyle.lineclamp} ${NewsArticleStyle.newsContent}`}
                                            dangerouslySetInnerHTML={{
                                                __html: data.content.length > 200 // Check if the content is longer than 200 characters
                                                    ? data.content.substring(0, 200) + "..." // Display the first 200 characters followed by an ellipsis
                                                    : data.content // Display the full content if it's 200 characters or less
                                            }}
                                            style={{
                                                fontSize: "15px",
                                                fontWeight: "300",
                                                color: "#585858"
                                            }}
                                        ></p>

                                    </a>
                                );
                            })
                            :
                            <>
                                <div className={NewsArticleStyle.placeholderNewsParent} >


                                    {Array.from({ length: 3 }).map((_, index) => (
                                        <div key={index} className={NewsArticleStyle.placeholderNewsDiv}>
                                            <div className={NewsArticleStyle.placeholderNewsHead}></div>
                                            <div className={NewsArticleStyle.placeholderNewssubHead}></div>
                                            <p className={NewsArticleStyle.placeholderNewsPara}></p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }

                    </Box>
                </Box>
                <Box className={NewsArticleStyle.ArticleDown}>
                    <Typography variant='h1'>Upcoming Events</Typography>
                    <Box className={NewsArticleStyle.ContentImg}>
                        <Box className={NewsArticleStyle.ArticleBox}>
                            {
                                data.slice(0, 5)
                                    .map((work) => {
                                        return <ArticleWork eventName={work.eventName} eventDesc={work.eventDesc} eventDate={work.eventDate} eventLink={work.eventLink} />
                                    })
                                    .reverse()
                            }

                        </Box>
                    </Box>
                </Box>

            </div>
        </div>


    )
}

export default NewsArticle
