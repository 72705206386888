import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import InvestOpp from 'components/InvestOpp/InvestOpp'
import InvestorBanner from 'components/InvestorBanner/InvestorBanner'
import React from 'react'
import { Helmet } from "react-helmet";
const InvestorOppPage = () => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Helmet>
                <html lang="en" />
                <title>KJSS COSMOS</title>
                <meta name="description" content="" />
                <meta name="title" content="" />
                <meta name="keywords" content="" />

            </Helmet>
    <div 
    onContextMenu={handleContextMenu}
    >
        <NavBar/>
        <InvestorBanner/>
        <InvestOpp/>
        <Footer/>
    </div>
    </>
  )
}

export default InvestorOppPage