import React from 'react'
import InvBanStyles from './InvestorBanner.module.css'
import { Typography } from '@mui/material'

const InvestorBanner = () => {
    return (
        <div className={InvBanStyles.bannerStart}>
            <div className={InvBanStyles.banner1}>
                <Typography variant='h2'>Investors</Typography>
                <Typography variant='h6'>At KJSS, we are committed to creating long-term value for our investors. We believe that our innovative solutions, strong management team, and dedication to excellence will continue to drive our success in the years ahead. We believe that if supported by the right investment partners, we can achieve our goal of making a positive impact on the steel industry.
                </Typography>
                <Typography variant='h4'>At KJSS, we are committed to creating long-term value for our investors. We believe that our innovative solutions, strong management team, and dedication to excellence will continue to drive our success in the years ahead. We believe that if supported by the right investment partners, we can achieve our goal of making a positive impact on the steel industry.
                </Typography>
            </div>
        </div>
    )
}

export default InvestorBanner