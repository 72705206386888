import React from 'react'
import pdfstyle from "./InvPdf.module.css"
import { useState, useEffect } from "react"
import axios from 'axios'

import { useParams } from 'react-router-dom';


const InvPdf = () => {
  const {channelId } = useParams();

  const [data, setData] = useState([])

  const invAllData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_COSMOSINV}/channel/getChannelById/${channelId}`

      )
      setData(res.data)
      console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    invAllData()
  }, [])

  return (
    <div className={pdfstyle.Invpdfmain}>
      <div className={pdfstyle.Brochuremain}>
        {/* <iframe allowfullscreen="allowfullscreen" className="fp-iframe" src={data.pdfFile}
            style={{ border: ' 1px solid lightgray', width: ' 100%', height: '100vh' }}></iframe> */}
        {/* <iframe src="https://publuu.com/flip-book/180040/442813/page/1?embed" width="100%" height="580" scrolling="no" frameborder="0" allowfullscreen="" allow="clipboard-write" class="publuuflip"></iframe> */}
        {/* {
          data.map((Item) => */}

            <iframe src={data.pdf?.pdfFile} width="100%"  scrolling="no" frameborder="0" allowfullscreen="" allow="clipboard-write" class="publuuflip"></iframe>
{/* 
          )
        } */}
      </div>
    </div>

  )
}

export default InvPdf
