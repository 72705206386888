import React from 'react'
import SustDevStyles from './AboutSustnableDelpSec.module.css'
import { Box, Typography } from '@mui/material'
import { Image } from 'cloudinary-react'

function AboutSustnableDelpSec() {
    return (
        <div className={SustDevStyles.OurStoryMainSec}>
            <div className={SustDevStyles.OurStoryInnerSec}>
                <Box className={SustDevStyles.infosec}>
                    <Typography variant='h4'>
                        Sustainable development
                    </Typography>
                    <Typography variant='h6'>
                    We are committed to sustainability and social responsibility. We believe that the steel industry has a duty to reduce its environmental impact and to operate in a way that benefits both people and the planet. Hence, we have implemented a number of initiatives to reduce our carbon footprint, conserve resources, and support local communities. Our start-up is driven by a passion for steel and a belief in its potential to change the world. We believe that by working together, we can build a better, brighter, and more sustainable future for us all and that steel has the power to transform the world, and we are committed to unlocking its full potential.
                    </Typography>
                </Box>

                <Box className={SustDevStyles.imgsec}>
                    <Box className={SustDevStyles.imgBOX}>
                        <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681544303/Sustainable_development_acxmwz.svg" cloudName="dxluokypg" />
                    </Box>
                </Box>
            </div>
        </div>
    )
}

export default AboutSustnableDelpSec
