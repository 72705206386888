import React from 'react'
import EpcStyle from "./EpcAggregatorCard.module.css"
import { Box, Typography } from '@mui/material'
import { useState, useEffect } from "react"
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';




const EpcAggregatorCard = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [epc, setEpc] = useState([])
    const [token, setToken] = useState("");
    const [unauthorizied, setUnauthorizied] = useState(false);
   useEffect(() => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const ttoken = url.searchParams.get('token');

//   console.log(ttoken)
        if (ttoken) {
      
            setToken(ttoken);
        } else {
         
            navigate("/investopp")
        }
}, []);

    const epcAllData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSINV}/investmentOpportunities/getInvestmentOpportunitiesById/${id}/${token}`
            ).then(res => {
                setEpc(res.data)
                console.log(res.data)
            }).catch((err) => {
                console.log(err.response.status);

                setUnauthorizied(true);

            })

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        epcAllData()
    }, [token])


const handleClick = (type,channelId) => {
    switch(type)
    {
        case 'pdf':navigate(`/inv-pdf/${channelId}`);
            break;
        case 'pitch':navigate(`/investment-opportunities/${epc.invId}/${channelId}`);
            break;
        case 'video':navigate(`/inv-video/${channelId}` );
            break;
        default:console.log("no type selected");

    }
}


    return (
        <div className={EpcStyle.EpcAggrMain}>
            <Box className={EpcStyle.EpcAggrStart}>
                <Typography variant='h1'>Learn more about channels</Typography>
                <Box className={EpcStyle.EpcAggrCard}>
                    {
                        unauthorizied ?
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <h1 style={{color:"red"}}>Your are not Authorized! Please Ask for Authorization.</h1>
                            </div>

                            :
                            epc.channels?.map((item) =>
                                <Box className={EpcStyle.EpcAggrBox} key={item.channelId}>
                                    <div onClick={() => handleClick((item.channelType.toLowerCase()), item.channelId)} target='_blank' style={{ textDecoration: 'none', }}>
                                        <div className={EpcStyle.EpcAggrImg}>
                                            <img src='https://res.cloudinary.com/dxluokypg/image/upload/v1687513504/channels-collaborate-effectively-in-channels_2x.jpg_ct3wmu.svg'></img>
                                        </div>
                                        <div className={EpcStyle.EpcAggrContent}>
                                            <Typography variant='h4'>{item.channelType}</Typography>
                                            <Typography variant='h5'>{item.channelName}</Typography>
                                            <div className={EpcStyle.EpcAggrdiv}>
                                                <a href=''>Learn more</a>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            )
                    }
                </Box>
            </Box>
        </div>
    )
}

export default EpcAggregatorCard
