import React from 'react'
import AboutUsstyle from './CosmosAboutUs.module.css'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import { Image } from 'cloudinary-react';
import { useState } from 'react';


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
  };
  return (
      <p className="text" >
          {isReadMore ? text.slice(0, 255) : text}
          <span onClick={toggleReadMore} className="read-or-hide" style={{ color: '#16469D' }}>
              {isReadMore ? ". Read more" : " Show less"}
          </span>
      </p>
  );
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CosmosAboutUs = () => {
  return (
    <div className={AboutUsstyle.AboutUsMain}>
        <div className={AboutUsstyle.AboutUsStart}>
            <Box className={AboutUsstyle.AboutUsContent}>
              <Box className={AboutUsstyle.Content1}>
                <Typography variant='h2'>About us</Typography>
                
                <Typography variant='h5'><ReadMore>We are a start-up on a mission to create a difference in the steel industry. Our team is a lethal combination of industry veterans and young, dynamic, and forward-thinking individuals who are passionate about steel and its potential to transform the world. We are not bound by tradition or conventional thinking, but rather embrace innovation and creativity to find new and better ways of doing things. We believe that the industry is ripe for innovation and that there is a huge opportunity to create products and services that meet the needs of modern society.</ReadMore></Typography>
                
                <Link to="/about-us" style={{ textDecoration: 'none'}}>
                <Button variant="contained">Read More</Button>
                </Link>
              </Box>
            </Box>
            <Box className={AboutUsstyle.AboutUsImage}>
                <Typography variant='h2'>About us</Typography>
            <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1681713336/Mask_group123_qnllmr.jpg" cloudName="dxluokypg" />
              </Box>
        </div>
    </div>
  )
}

export default CosmosAboutUs
