import CosmosAboutUs from 'components/CosmosAboutUs/CosmosAboutUs'
import CosmosBanner from 'components/CosmosBanner/CosmosBanner'
import CosmosNews from 'components/CosmosNews/CosmosNews'
import CosmosNewslatter from 'components/CosmosNewslatter/CosmosNewslatter'
import CosmosOurMission from 'components/CosmosOurMission/CosmosOurMission'
import OurMissionInfo from 'components/OurMissionInfo/OurMissionInfo'
import MeetOurTeamSection from 'components/MeetOurTeam/MeetOurTeamSection'
import React from 'react'
import NavBar from 'components/Header/NavBar'
import Footer from 'components/Footer/Footer'
import OurBusinessesSection from 'components/OurBusinessesSection/OurBusinessesSection'
import { Helmet } from "react-helmet";
const CosmosMainPage = () => {

    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Home | Knowing judging Sourcing | kjss cosmos | Knowing Steel</title>
                <meta name="title" content="Knowing judging Sourcing | kjss cosmos | Knowing Steel" />
                <meta name="description" content="Knowing judging Sourcing is a crucial and important part in the steel manufacturing process. kjss cosmos provides the best and most reliable solutions to all the sourcing needs of the clients." />
                <meta name="keywords" content="" />

            </Helmet>
            <div 
            onContextMenu={handleContextMenu}
            >
                <NavBar />
                <CosmosBanner />
                <CosmosAboutUs />
                <OurBusinessesSection />
                <MeetOurTeamSection />
                <CosmosOurMission />
                <OurMissionInfo />
                <CosmosNews />
                <CosmosNewslatter />
                <Footer />
            </div>
        </>

    )
}

export default CosmosMainPage
