import React, { useEffect, useState } from 'react'
import InvestOppStyles from './InvestOpp.module.css'
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';



const portals = [
    {
        id: 1,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552865/Introduction_sbltnq.svg'),
        title: 'Introduction',
        link: 'https://docs.google.com/presentation/d/e/2PACX-1vR3NPjDLGLLQj-lmEWD2emgY8nRhHnjmvEfGfPMFEnTdG4YJ4BIROi1C4z9DhmkHA/pub?start=false&loop=false&delayms=3000'
    },
    {
        id: 2,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552824/Overview_d0nbyx.svg'),
        title: 'Overview',
        link: 'https://docs.google.com/presentation/d/e/2PACX-1vRrl-tVI-Mwsv43AvJnUTEfRmJhDTmobBao2_PNgTPNsoYLj51xtiyOneVrLQ4bnOPh2fwCNbKBqUta/pub?start=false&loop=false&delayms=3000'
    },
    {
        id: 3,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552822/KIOCL_kzvvrt.svg'),
        title: 'KIOCL',
        link: 'https://docs.google.com/presentation/d/e/2PACX-1vQfMhhywC6DOe0cxuT3O4T7MtdxMn3mx4q13FR9iUTxd4FYz5L1DC0ZjGlSgJa8w_wAjsXUvB6sP1Qw/pub?start=false&loop=false&delayms=3000'
    },
    {
        id: 4,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681559239/EPC_Aggregator__1_dei09q.svg'),
        title: 'EPC Aggregator',
        // link: 'https://drive.google.com/file/d/1k7GMEOA6Y33itIs6CJpQUXMqKjABSFBr/view?usp=sharing '
        // link: 'http://192.168.0.219:3000/#/Epc%20aggre',
        link: 'https://www.kjsscosmos.com/#/Epc%20aggre',
    },
    {
        id: 5,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552823/SolidWaste_qbl66v.svg'),
        title: 'Solid Waste Management',
        link: 'https://drive.google.com/file/d/1ioALqsAOQYBjnKlnd47ReD8IUZxFvj8p/view?usp=sharing'
    },
    {
        id: 6,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552823/ResortsLeisure_tiqnid.svg'),
        title: 'Resorts and Leisure Home',
        link: 'https://docs.google.com/presentation/d/e/2PACX-1vQGD9MRdEWmKoNwZx3s1Er_JyWtE_I2KzOuAVzanli14Z-Dcw08GivjULJIVlSO9bbnkeJj55uF53D8/pub?start=false&loop=false&delayms=3000'
    },
    {
        id: 7,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681552823/ECommerce_occwdr.svg'),
        title: 'E-Commerce',
        link: 'http://ecom.kjssteel.com/'
    },
    {
        id: 8,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681558025/RateContract_iaq1jz.svg'),
        title: 'Rate Contract',
        link: 'https://www.figma.com/proto/fotXa3nOyXvDKHYOcFocXe/KJS-Steel?page-id=0%3A1&node-id=55-244&viewport=444%2C3184%2C0.14&scaling=min-zoom&starting-point-node-id=442%3A1459'
    },
    {
        id: 9,
        linkImg: ('https://res.cloudinary.com/dxluokypg/image/upload/v1681559496/WhatsApp_Image_2023-04-15_at_4.45_1_khiwyx.svg'),
        title: 'Information Portal',
        link: 'https://newz.kjssteel.com//'
    }
]

const InvestOpp = () => {

    const { id, id2, invId } = useParams();

    const [data, setData] = useState([])

    const getInvoppData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSINV}/investmentOpportunities/getAllInvestmentOpportunities`
            )
            setData(res.data)
            console.log(res.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getInvoppData();
    }, [])


    return (

        <div className={InvestOppStyles.invest_opp_main}>
            <div className={InvestOppStyles.invest_opp_content}>
                <Typography className={InvestOppStyles.invest_opp_heading}>Investment Opportunities</Typography>
                <div className={InvestOppStyles.invest_opp_cards} >
                    <Grid container spacing={{ xs: 4, sm: 4, md: 4, lg: 3 }} sx={{ display: 'flex', flexDirection: { sm: "column", md: "row", alignItems: 'center', justifyContent: 'center' } }}>
                        {data.map((portal) =>

                            <Grid item key={portal.invId}>
                                {/* <a href={portal.link} target='_blank' style={{ textDecoration: 'none', }}> */}
                                <Link to={`/permission/${portal?.invId}`} style={{ textDecoration: 'none', }} >
                                    {/* <Link to='/permission'> */}
                                    <Card className={InvestOppStyles.investcardStart}
                                        sx={{
                                            width: '300px', height: '200px', boxShadow: 'none', background: `none, url(${`${process.env.REACT_APP_COSMOSINV}/investmentOpportunities/file/${portal.photos?.newImageName}`})`,
                                            backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                                        }}>

                                        <CardActionArea>
                                            <CardContent sx={{ mt: '35%'}} >
                                                <div className={InvestOppStyles.cardContent}>
                                                    <h6  style={{ textDecoration: 'none' }}>
                                                        {portal.invName}
                                                    </h6>
                                                    <Typography variant="body3" color=' #ffffff' fontWeight={270} fontSize={12} sx={{
                                                        textDecoration: 'underline', textShadow: '12px 12px 14px rgba(0, 0, 0, 1.9)', 
                                                    }}>
                                                        Know More
                                                    </Typography>
                                                </div>
                                            </CardContent> 
                                      </CardActionArea>
                                    </Card>
                                </Link>
                                {/* </a> */}
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default InvestOpp