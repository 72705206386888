import React from 'react'
import Box from '@mui/material/Box';
import Styles from './CareerBannerPage.module.css'
import { Typography } from '@mui/material';
import { useState } from 'react';





function CareerBannerPage() {
    return (
        <div>

            <Box className={Styles.BgImg}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}>
                <Typography variant='h3'
                    sx={{
                        fontSize: '36px',
                        fontWeight: '500',
                        color: '#FFFFFF',
                        lineHeight: '42px',
                        paddingBottom:'2%',
                        paddingTop:'2%',
                    }}>
                    Join us</Typography>
                <Typography variant='h6'
                    sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#FFFFFF',
                        lineHeight: { xs: '15px', sm: '20px', md: '22px', lg: '24px' },
                        width: { xs: '90%', sm: '80%', md: '70%', lg: '48%' },
                        textAlign: 'center',

                    }}>
                    Are you looking to build a career with a start-up company? If so, then you're in the right place! Start-ups offer a unique and exciting opportunity to work in a dynamic, fast-paced environment that values innovation, creativity, and flexibility.
</Typography>
            </Box>

           
        </div>
    )
}

export default CareerBannerPage