import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KjssLogo from '../../assets/images/logos/Logo-KJSSCOSMOS.svg'
import Navbar from './NavBar.module.css'
import { NavLink, Link } from 'react-router-dom'
import { Business, Height } from '@mui/icons-material'
import Select from '@mui/material/Select'
import { useState, useEffect } from 'react'
import { Image } from 'cloudinary-react'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import axios from 'axios'

// dropdown
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

const navItems = [
    'Home',
    'About us',
    'Business',
    'Careers',
    'Investor',
    'Media',

]
const NavMenus = [
    {
        id: 1,
        name: 'Steel',
        link: 'http://kjssteel.com/',
    },
    {
        id: 2,
        name: 'E-Commerce',
        link: 'http://ecom.kjssteel.com/',
    },
    {
        id: 3,
        name: 'Product Catalogue',
        link: 'https://www.kjssteel.com/product-catalogue',


    }
]

function NavBar(props) {
    const { window } = props
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
        // alert("IM CLICKED")
        console.log("mobileOpen:", mobileOpen);
    }

    // dorpdwon start
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    // dorpdwon end

    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    const navLinkStyles = ({ isActive }) => {
        return {
            textDecoration: isActive ? 'none' : 'none',
            color: isActive ? '#16469d' : '#656363'

        }
    }
    const [openBusiness, setOpenBusiness] = React.useState(false);


    const handleBusinessClick = () => {
        setOpenBusiness((prevOpen) => !prevOpen);
    };

    const handleBusinessMouseLeave = () => {
        setOpenBusiness(false); // Close the dropdown when the mouse leaves
    };


    const [data, setData] = useState([])
    const getAllBusinessData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_COSMOSADMIN}/business/getAllBusinessDetails`
            )
            setData(res.data)
            console.log(res.data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllBusinessData()
    }, [])




    const drawer = (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Divider sx={{ width: '100%' }} />

            <List>
                {navItems.map((item) =>
                    item === 'Business' ? (
                        <Box key={item}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleBusinessClick} >
                                    <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: '600', color: '#585858' }}>{item}</ListItemText>

                                </ListItemButton>

                            </ListItem>
                            <Collapse in={openBusiness} timeout="auto" unmountOnExit>


                                {data?.map((list) => {
                                    return (
                                        <List component="div" disablePadding>
                                            <ListItemButton key={list.id}>
                                                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '400' }}>
                                                    <a target='_blank' href={list?.business?.businessLink} style={{ textDecoration: 'none', color: '#585858' }}>{list?.business?.businessHeading}</a>

                                                </ListItemText>
                                            </ListItemButton>
                                        </List>
                                    )
                                })}
                                {/* <List component="div" disablePadding>
                                    <ListItemButton >
                                        <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '400' }}><a href='https://www.kjssteel.com/' target='_blank' style={{ textDecoration: 'none', color: '#585858' }}>Steel</a></ListItemText>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton>
                                        <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '400' }}><a href='https://ecom.kjssteel.com/' target='_blank' style={{ textDecoration: 'none', color: '#585858' }}>E-commerce</a></ListItemText>
                                    </ListItemButton>
                                </List>

                                <List component="div" disablePadding>
                                    <ListItemButton>
                                        <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '400' }}><a href='https://www.kjssteel.com/product-catalogue' target='_blank' style={{ textDecoration: 'none', color: '#585858' }}>Product Catalog</a></ListItemText>
                                    </ListItemButton>
                                </List> */}
                            </Collapse>
                        </Box>
                    ) :
                        (
                            <ListItem key={item} disablePadding>
                                <ListItemButton sx={{ textAlign: 'center' }}>

                                    <NavLink
                                            to={`/${item.toLowerCase().replace(/\s/g, '-')}`}
                                        style={navLinkStyles}
                                    >
                                        <ListItemText primary={item} primaryTypographyProps={{ fontWeight: '600' }} />
                                    </NavLink>

                                </ListItemButton>
                            </ListItem>
                        ))}
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <NavLink
                            to='/contact-us'
                            style={navLinkStyles}
                        >
                            <ListItemText primary='Contact us' primaryTypographyProps={{ fontWeight: '600' }} />
                        </NavLink>

                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    const container =
        window !== undefined ? () => window().document.body : undefined




    return (
        <Box>
            <CssBaseline />
            <AppBar component="nav" sx={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#ffffff', alignItems: 'center', padding: '0.6% 0' }}>
                <Toolbar className={Navbar.NavbarMain}>
                    <IconButton
                        color="red"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        // sx={{ mr: 2, }}
                        className={Navbar.toggleBtn}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box className={Navbar.MainClass}>
                        <NavLink to="/home">
                            <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682313765/KJS_cosmos_new_LOGO_FINAL_qjrgf3.svg" cloudName="dxluokypg" />
                        </NavLink>
                    </Box>
                    <Box className={Navbar.navLinks}
                        sx={{
                            display: {
                                sm: 'none',
                                // sm: 'block',
                                // md: 'block',
                                // lg: 'block',
                            }
                        }}
                    >


                        <Box className={Navbar.NavStart}

                            sx={{
                                display: {
                                    sm: 'none',
                                    // sm: 'block',
                                    // md: 'block',
                                    // lg: 'block',
                                },
                                // textTransform: 'none',
                                // flexWrap: 'wrap',
                                // width: '45%',
                                // display: 'flex',
                                // justifyContent: 'space-around',
                            }}
                        >
                            {navItems.map((item) =>
                                item === 'Business' ?
                                    (
                                        <Button
                                            key={item}
                                            // onMouseEnter={handleOpenUserMenu}
                                            className={Navbar.bussinessDropDown}

                                            sx={{
                                                // width:'100%', 
                                                border: 'none',
                                                textTransform: 'none',
                                                textDecoration: 'none',
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '10px',
                                                    md: '12px',
                                                    lg: '14px',
                                                },
                                                fontWeight: '450',
                                                lineHeight: '22px',
                                                color: '#585858',
                                            }}
                                        >

                                            {item}
                                            <ul className={Navbar.bussinessDropDownUl} style={{width:'200px',maxWidth:'300px',minWidth:'150px'}}>
                                                {data?.map((list) => {
                                                    return (
                                                        <li key={list.id}>
                                                            <a target='_blank' href={list?.business?.businessLink}>{list?.business?.businessHeading}</a></li>
                                                    )
                                                })}
                                            </ul>
                                        </Button>
                                    )

                                    :


                                    (
                                        <Button
                                            key={item}
                                            sx={{
                                                textTransform: 'none',
                                                textDecoration: 'none',
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '10px',
                                                    md: '12px',
                                                    lg: '14px',
                                                },
                                                fontWeight: '450',
                                                lineHeight: '22px',
                                                color: '#585858',
                                            }}
                                        >
                                            <NavLink
                                                // to={`/${item}`}
                                                to={`/${item.toLowerCase().replace(/\s/g, '-')}`}
                                                style={navLinkStyles}

                                            >
                                                {item}
                                            </NavLink>
                                        </Button>

                                    )
                            )}
                        </Box>
                        <Link to="/contact-us" className={Navbar.Btn} style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    width: '100%',
                                    color: '#fff',
                                    bgcolor: '#16469D',
                                    fontWeight: '510',
                                    lineHeight: '23px',
                                    letterSpacing: '6%',
                                    padding: '8px 15px ',
                                    fontSize: {
                                        xs: '8px',
                                        sm: '9px',
                                        md: '11px',
                                        lg: '14px',
                                    },
                                    '&:hover': { backgroundColor: '#2458B7' },
                                }}
                            >
                                Contact us
                            </Button>
                        </Link>
                    </Box>

                    <div></div>
                </Toolbar>
            </AppBar>
            <Box component="nav" className={NavBar.drawerView}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: "80%" },
                    }}


                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main">
                <Toolbar />
            </Box>
        </Box>
    )
}

export default NavBar
