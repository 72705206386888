import GalleryStyle from "./MediaGallery.module.css"
import { div, Typography } from '@mui/material'
import React from "react"
import { useState, useEffect } from "react"
import axios from 'axios'
import Slider from 'react-slick';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import newImage from '../../assets/images/microphone.jpg'
import { Link, useNavigate } from 'react-router-dom';
import LazyLoad from "react-lazyload"



const MediaGallery = () => {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [data, setData] = useState([])

    const getGalleryData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_COSMOSADMIN}/meta/getAllPosts`);
            
            // Check if data is returned and slice it to only include 0-3 indices
            const slicedData = res.data.map(cat => ({
                ...cat,
                subCategories: cat.subCategories.map(subCat => ({
                    ...subCat,
                    articles: subCat.articles.slice(0, 4) 
                }))
            }));
            // console.log(res.data.length); 
            // console.log(res.data[0].subCategories.length);
            // console.log(res.data[0].subCategories[0].articles.length); 
            setData(slicedData);
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        getGalleryData()
    }, [])




    return (
        <div className={GalleryStyle.MediaGalleryMain}>
            <div className={GalleryStyle.MediaGalleryStart}>
                <p>Gallery </p>

                {
                    data.map((cat) => {
                        return cat?.subCategories.map((item, index) => {
                            return (
                                <div key={index} className={GalleryStyle.BoxStart}>

                                    <div className={GalleryStyle.header} >
                                        <p> {item.name} </p>
                                        <Link to={`newsletter/${item.id}`} >View All</Link>
                                    </div>
                                    <div className={GalleryStyle.GridContainer}>


                                        {item.articles.map((contentItem, contentIndex) => (
                                            <Link to={contentItem.link} target="_blank" key={contentIndex} className={GalleryStyle.MediaBox}>
                                            <LazyLoad height={200} offset={100} once>
                                            <div className={GalleryStyle.MediaImg}>
                                                <img src={contentItem.url} alt='' />
                                            </div>
                                        </LazyLoad>
                                                <div className={GalleryStyle.imgInfo}>
                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{contentItem?.createdOn?.split("T")[0]} </span>
                                                        <Link to={contentItem.link} target="_blank"> <ArrowOutwardIcon /></Link>
                                                    </div>
                                                    <h4>{contentItem.title}.</h4>
                                                    <p>{contentItem?.description?.length > 120 ? `${contentItem?.description?.slice(0, 120)}...` : contentItem?.description}</p>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>


                                </div>
                            )
                        }
                        )

                    })

                }
            </div>
        </div >
    )
}

export default MediaGallery