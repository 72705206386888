import React from 'react'
import request from "./Request.module.css"
import { Typography } from '@mui/material'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';


const Request = () => {
    const { invId } = useParams();

    return (
        <div className={request.RestMain}>
            <div className={request.RestStart}>
                <div className={request.RestBox1}>
                    <Typography variant='h1'>Your request has been sent</Typography>
                    <Typography variant='h5'>You'll receive an email once the request has been approved</Typography>
                    {/* <Link to='/permission'> */}
                    <Link to={`/permission/${invId}`}>
                    <Button variant="contained" id='button'>Go Back</Button>
                    </Link>
              
                </div>
                <div className={request.RestBox2}>
                    <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1688641885/Sent_for_appproval_td790n.gif" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Request
