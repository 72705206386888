import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CformStyle from './css/ContactUsForm.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { setContactUsEmail, setContactUsFullName, setContactUsMessage, setContactUsPhoneNumber } from './store/dataSlice';
import { contact } from './store/Contact.actions';

function ContactUsForm() {
    const dispatch = useDispatch()

    const contactState = useSelector((state) => state.contact)

    const [data, setData] = useState({
        contactUsFullName: '',
        contactUsEmail: '',
        contactUsPhoneNumber: '',
        contactUsMessage: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = () => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const regexphone = /^(\+|\d)[0-9]{7,16}$/;
        const regexname = /^[A-Za-z\s]+$/; // Regular expression to allow only letters and spaces in the name field
        if (!data.contactUsFullName) {
            errors.contactUsFullName = 'Name is required!';
        } else if (!regexname.test(data.contactUsFullName)) {
            errors.contactUsFullName = 'Name can only contain letters and spaces!';
        }

        if (!data.contactUsEmail) {
            errors.contactUsEmail = 'Email is required!';
        } else if (!regexemail.test(data.contactUsEmail)) {
            errors.contactUsEmail = 'Please enter a valid email.';
        }
        if (!data.contactUsPhoneNumber) {
            errors.contactUsPhoneNumber = 'Mobile Number is required!';
        } else if (!regexphone.test(data.contactUsPhoneNumber)) {
            errors.contactUsPhoneNumber = 'Enter a valid phone number.';
        }
        if (!data.contactUsMessage) {
            errors.contactUsMessage = 'Please write a message.';
        }

        return errors;
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // console.log(data)
        // setErrors(validate(data))
        // setIsSubmit(true)
        const validationErrors = validate();
        setErrors(validationErrors);
        setIsSubmit(true);
        // }

        // useEffect(() => {
        //     if (Object.keys(errors).length === 0 && isSubmit) {
        //         dispatch(setContactUsFullName(data.contactUsFullName))
        //         dispatch(setContactUsEmail(data.contactUsEmail))
        //         dispatch(setContactUsPhoneNumber(data.contactUsPhoneNumber))
        //         dispatch(setContactUsMessage(data.contactUsMessage))
        //         dispatch(contact(data))
        //         setIsSuccess(true)
        //         setData({
        //             contactUsFullName: '',
        //             contactUsEmail: '',
        //             contactUsPhoneNumber: '',
        //             contactUsMessage: '',
        //         })
        //         setTimeout(() => {
        //             setIsSuccess(false);
        //             alert('Form submitted successfully!');

        //         }, 2000)
        //     }
        // }, [errors, isSubmit])

        if (Object.keys(validationErrors).length === 0) {
            dispatch(setContactUsFullName(data.contactUsFullName));
            dispatch(setContactUsEmail(data.contactUsEmail));
            dispatch(setContactUsPhoneNumber(data.contactUsPhoneNumber));
            dispatch(setContactUsMessage(data.contactUsMessage));
            dispatch(contact(data));
            setIsSuccess(true);
            setData({
                contactUsFullName: '',
                contactUsEmail: '',
                contactUsPhoneNumber: '',
                contactUsMessage: '',
            });
            setTimeout(() => {
                setIsSuccess(false);
                alert('Form submitted successfully!');

            }, 2000);
        }
    };
    function blockNumbers(event) {
        const input = event.target;
        const inputValue = input.value;

        const sanitizedInput = inputValue.replace(/[0-9]/g, '');

        input.value = sanitizedInput;
    }
    function blockCharacter(event) {
        const input = event.target;
        const inputValue = input.value;

        const sanitizedInput = inputValue.replace(/[A-Za-z]/g, '');

        input.value = sanitizedInput;
    }




    return (
        <div className={CformStyle.ContactUsForm}>
            <Card className={CformStyle.ContactCard}>

                <CardContent className={CformStyle.ContactCardContent}>

                    <div style={{ width: '95%', margin: '10px 0' }}>
                        <Typography variant='h4'
                            sx={{
                                color: '#585858',
                                fontWeight: '700',
                                width: '100%',

                                fontSize: { xs: '15px', sm: '16px', md: '18px', lg: '20px' },
                                lineHeight: { xs: '32px', sm: '40px', md: '55px', lg: '64px' },
                                textAlign: { xs: 'left', sm: 'left', md: 'left', lg: 'center' },

                            }}>Let’s Start a Conversation</Typography>

                    </div>
                    <Grid xs={12} lg={12} container spacing={4} className={CformStyle.ContactUsFormStart} >
                        <Grid xs={12} sm={12} md={6} lg={6}
                            item >
                            <Box sx={{
                                bgcolor: '#F6FAFF',
                                display: 'flex',
                                textAlign: 'start',
                                width: '100%',
                            }}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            boxSizing: 'border-box',
                                            padding: '25px 15px',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: '#16469D',
                                                opacity: '60%',
                                                fontWeight: '800',
                                                fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px', xl: '18px' },
                                                lineHeight: '20px',

                                            }}
                                        >Head Office :</Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '12px', sm: '14px', md: '15px', lg: '15px' },
                                                lineHeight: { xs: '16px', sm: '18px', md: '20px', lg: '22.5px' },
                                                mt: '3%',
                                                width: { sm: '70%', lg: '70%' }
                                            }}>Kamdhenu Commerz Commercial
                                            Business Park, Sector 14,Kharghar,
                                            Navi Mumbai, Maharashtra 410210</Typography>
                                        <Typography
                                            sx={{
                                                mt: { xs: '1%', sm: '2%', md: '2%', lg: '7%' },
                                                color: '#16469D',
                                                opacity: '60%',
                                                fontWeight: '800',
                                                fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px', xl: '18px' },
                                                lineHeight: '20px',
                                            }}>Working hours :</Typography>
                                        <Typography
                                            sx={{
                                                mt: { xs: '3%' },
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '15px' },
                                                lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },
                                            }}>Monday To Friday</Typography>
                                        <Typography
                                            sx={{
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '12px', sm: '14px', md: '15px', lg: '15px' },
                                                lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },
                                            }}>9:00 AM to 8:00 PM </Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '13px', sm: '14px', md: '15px', lg: '15px' },
                                                lineHeight: { xs: '15px', sm: '22px', md: '25px', lg: '30px' },
                                            }}>Our Support Team is available 24/7</Typography>
                                        <Typography
                                            sx={{
                                                mt: { xs: '1%', sm: '2%', md: '2%', lg: '7%' },
                                                color: '#16469D',
                                                opacity: '60%',
                                                fontWeight: '800',
                                                fontSize: { xs: '12px', sm: '14px', md: '14px', lg: '14px', xl: '18px' },
                                                lineHeight: '20px',
                                            }}>Contact Us :</Typography>
                                        <Typography
                                            sx={{
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '13px', sm: '14px', md: '15px', lg: '15px' },
                                                lineHeight: { xs: '18px', sm: '20px', md: '30px', lg: '30px' },
                                                mt: { xs: '3%', sm: '3%', md: '', lg: '' },

                                            }}>
                                            <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ textDecoration: 'none', listStyle: 'none', color: '#585858' }}><li>+91 93217 14104</li></a>

                                        </Typography>
                                        <Typography variant='h5'
                                            sx={{
                                                color: '#585858',
                                                fontWeight: '500',
                                                fontSize: { xs: '13px', sm: '14px', md: '15px', lg: '15px' },
                                                lineHeight: { xs: '13px', sm: '22px', md: '25px', lg: '32px' },
                                                pb: { xs: '1%', sm: '1%', md: '1%', lg: '5%' },
                                            }}>Info@kjsscosmos.com </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Grid>

                        <Grid xs={12} md={6} lg={6}
                            item>

                            <form onSubmit={handleSubmit} className={CformStyle.ContactFormTextField}>
                                <div>
                                    <input
                                        type='text'
                                        label="Your Name" id="outlined-basic" className={CformStyle.ContInput} placeholder='Full Name' variant='outlined' fullWidth required
                                        value={data.contactUsFullName}
                                        onInput={(e) => blockNumbers(e)}
                                        autoComplete="off"
                                        pattern="[A-Za-z ]*"
                                        onChange={(e) => handleChange(e, 'contactUsFullName')}
                                    />
                                    {errors.contactUsFullName && <div className={CformStyle.errorsContactus}>{errors.contactUsFullName}</div>}
                                </div>

                                <div>

                                    <input type='email' label="Your Email" className={CformStyle.ContInput} placeholder='Your Email' variant='outlined' fullWidth required
                                        value={data.contactUsEmail}
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e, 'contactUsEmail')}
                                    />
                                    {errors.contactUsEmail && <div className={CformStyle.errorsContactus}>{errors.contactUsEmail}</div>}
                                </div>


                                <div>

                                    <input className={CformStyle.NumberArea} type='text' label="Phone Number" placeholder='Phone Number' variant='outlined' fullWidth required
                                        value={data.contactUsPhoneNumber}
                                        autoComplete="off"
                                        pattern="[0-9]*"
                                        onInput={(e) => blockCharacter(e)}
                                        onChange={(e) => handleChange(e, 'contactUsPhoneNumber')}

                                    />
                                    {errors.contactUsPhoneNumber && <div className={CformStyle.errorsContactus}>{errors.contactUsPhoneNumber}</div>}
                                </div>

                                <div>

                                    <textarea
                                        className={CformStyle.textareaInp}
                                        rows="5"
                                        column="10"
                                        placeholder="Message"
                                        fullWidth
                                        required
                                        value={data.contactUsMessage}
                                        onChange={(e) => handleChange(e, 'contactUsMessage')}
                                    />



                                </div>

                                <Button
                                    className={CformStyle.SubmitBtn}
                                    type="submit"
                                    variant="contained"
                                >
                                    Send Message
                                </Button>

                            </form>
                        </Grid>

                    </Grid>
                    {/* </Box> */}

                </CardContent>
            </Card>
        </div>
    )
}

export default ContactUsForm