import { Box, Typography } from '@mui/material'
import Docs from "./Document.module.css"
import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from "react"
import axios from 'axios'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';



const Document = ({data}) => {

    // const [data, setData] = useState([])
    // const [doc, setDoc] = useState([])
    const [docdetails, setDocdetails] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');

    // const docmentData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_COSMOSADMIN}/questionnaire/getAllQuestionnaire`
    //         )
    //         setDoc(res.data)
    //         console.log(doc)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     docmentData()
    // }, [])


    // const docAllData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_COSMOSADMIN}/documents/getAllDocuments`
    //         )
    //         setData(res.data)
    //         console.log(res.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handlePdfLink = async (detail) => {
        
        setDocdetails(detail);
        setPdfUrl('');
        if (detail && detail.documentId) {
            const documentId = detail.documentId;
            const apiUrl = `${process.env.REACT_APP_COSMOSINV}/documents/file/${detail.newDocumentName}`;

            axios.get(apiUrl, {
                responseType: 'blob' // set the response type to blob
            })
                .then(response => {
                    const blob = 
                    new Blob([response.data], { type: detail.documentType });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading PDF file', error);
                });
        }

    }
    // useEffect(() => {
    //     docAllData()
    // }, [])




    return (
        <div className={Docs.DocumentMain} id='tab4'>
            <div className={Docs.DocumentStart}>
                <Typography variant='h6'>Documents</Typography>
                <div className={Docs.DocumentBoxStart}>
                    {
                        data.map((detail) =>
                            <Box className={Docs.DocumentBox}>
                                <a onClick={() => handlePdfLink(detail)} target='_blank' className={Docs.detailLink}>
                                    <img src='https://res.cloudinary.com/dxluokypg/image/upload/v1687252990/xls-icon.png_uo0o0d.svg' />
                                </a>
                                <Typography variant='h5'>{(detail.documentName).substr(0,8)}</Typography>
                            </Box>
                        )
                    }

                </div>


                <Typography variant='h1' id='tab5'>Client questionnaire</Typography>

                <div className={Docs.AccordionMainSection} >
                    <Box className={Docs.AccordionMain}>
                        {data.map((item) =>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography className={Docs.AccordionSection} >{item.question}</Typography>
                                </AccordionSummary>

                                <AccordionDetails >
                                    <Typography className={Docs.AccordionAddress}>{item.answer}</Typography>
                                </AccordionDetails>

                            </Accordion>
                        )}
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Document
