import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        contactUsFullName: '',
        contactUsEmail: '',
        contactUsPhoneNumber: '',
        contactUsMessage: '',
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        setContactUsFullName: (state, action) => {
            state.contactUsFullName = action.payload
        },
        setContactUsEmail: (state, action) => {
            state.contactUsEmail = action.payload
        },
        setContactUsPhoneNumber: (state, action) => {
            state.contactUsPhoneNumber = action.payload
        },
        setContactUsMessage: (state, action) => {
            state.contactUsMessage = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
    }
})

export const {
    setContactUsFullName,
    setContactUsEmail,
    setContactUsMessage,
    setContactUsPhoneNumber,
    setLoading,
    setError,
    setSuccess,
} = contactSlice.actions

export default contactSlice.reducer