import ComingSoon from 'components/ComingSoon/ComingSoon'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import React from 'react'

const ComingSoonLandingPg = () => {
  return (
    <div>
        <NavBar/>
        <ComingSoon/>
        <Footer/>
    </div>
  )
}

export default ComingSoonLandingPg