import Disclaimer from 'components/Disclaimer/Disclaimer'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import React from 'react'
import { Helmet } from "react-helmet";
const DisclaimerPage = () => {
  return (
    <>
          <Helmet>
                <html lang="en" />
                <title>Disclaimer</title>
                <meta name="description" content="." />
                <meta name="title" content="" />
                <meta name="keywords" content="" />

            </Helmet>
    <div>
        <NavBar/>
        <Disclaimer/>
        <Footer/>
    </div>
    </>
  )
}

export default DisclaimerPage