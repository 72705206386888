import React from 'react'
import { useState, useEffect } from "react"
import { Button, Typography, TextField } from '@mui/material'
import Tmark from "./TheMarket.module.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { Image } from 'cloudinary-react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setcontactName, setcontactEmail, setcontactPhoneNumber } from './store/dataSlice';
// import { interested } from './store/Interested.action';
import {market} from "./store/Market.action"

import axios from 'axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 465,
    height: 535,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderTop: '8px solid #16469D',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};


const TheMarket = ({mark}) => {



    // const [mark, setMark] = useState([])

    // const marketData = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${process.env.REACT_APP_COSMOSADMIN}/graph/getAllGraphs`
    //         )
    //         setMark(res.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     marketData()
    // }, [])



    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // ---------------------------------------------------------

    const dispatch = useDispatch()

    const contactState = useSelector((state) => state.market)

    const [data, setData] = useState({
        contactName: '',
        contactEmail: '',
        contactPhoneNumber: '',
    })

    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange1 = (event, property) => {
        setData({ ...data, [property]: event.target.value })
    }

    const validate = (values) => {
        const errors = {}
        const regexemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const regexphone = /^(\+|\d)[0-9]{7,16}$/

        if (!values.contactName) {
            errors.contactName = 'Email is required!'
        }

        else if (!values.contactEmail) {
            errors.contactEmail = 'Email is required!'
        }
        else if (!regexemail.test(values.contactEmail)) {
            errors.contactEmail = 'Please enter a valid email.'
        }
        if (!values.contactPhoneNumber) {
            errors.contactPhoneNumber = 'Mobile Number is required!'
        }
        else if (!regexphone.test(values.contactPhoneNumber)) {
            errors.contactPhoneNumber = 'Enter a valid phone number.'
        }

        return errors
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(data)
        setErrors(validate(data))
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit) {
            dispatch(setcontactName(data.contactName))
            dispatch(setcontactEmail(data.contactEmail))
            dispatch(setcontactPhoneNumber(data.contactPhoneNumber))
            dispatch(market(data))
            setIsSuccess(true)
            setData({
                contactName: '',
                contactEmail: '',
                contactPhoneNumber: '',
            })
            setTimeout(() => {
                setIsSuccess(false)
            }, 2000)
        }
    }, [errors, isSubmit])

    return (
        <div className={Tmark.MarketMain} id="tab2" >
            <div className={Tmark.MarketStart}>
                <Typography variant='h2'>The Market</Typography>
                {
                    mark.map((item) =>
                        <div className={Tmark.MarketBox1} key={item.graphId}>
                            <Typography variant='h2'>{item.graphName}</Typography>
                                <Typography variant='h6'>{item.graphDesc}</Typography>
                        

                            <Box className={Tmark.MarketImg}>
                                <Image publicId={`${process.env.REACT_APP_COSMOSINV}/graph/file/${item.images.newImage}`} cloudName="dxluokypg" />
                            </Box>
                        </div>
                    )}

               
                <Button variant="contained" onClick={handleOpen}>I’m Interested</Button>
                <Modal className={Tmark.FAQModalSize}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box className={Tmark.FaqsModal} sx={style}>
                        <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                            <Typography id="modal-modal-title" variant="h5" sx={{ textAlign: 'left', fontSize: '18px', lineHeight: '20px', color: '#424242', fontWeight: '600', padding: '2% 0' }}>
                                Get in touch
                            </Typography>
                            <Typography onClick={handleClose} sx={{ textAlign: 'right', cursor: 'pointer' }}>
                                <CancelIcon sx={{ color: 'gray' }} />
                            </Typography>
                        </div>
                        <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '19.15px', color: '#424242', fontWeight: '500', padding: '2% 0', width: '70%', }} >
                            Please submit your details so that our executive can get back to you at the earliest
                        </Typography>
                        <Box className={Tmark.ModalInput}>
                            <form onSubmit={handleSubmit}>

                                <input type='Name' label="Your Name" className={Tmark.ContInput} placeholder='Your Name' variant='outlined' fullWidth required
                                    value={data.contactName}
                                    onChange={(e) => handleChange1(e, 'contactName')}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        color: '#232536',
                                        fontWeight: '400',
                                        lineHeight: '28px',
                                        mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                    }} />
                                <input type='Email' label="Your Email" className={Tmark.ContInput} placeholder='Your Email' variant='outlined' fullWidth required
                                    value={data.contactEmail}
                                    onChange={(e) => handleChange1(e, 'contactEmail')}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        color: '#232536',
                                        fontWeight: '400',
                                        lineHeight: '28px',
                                        mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },

                                    }} />
                                <input className={Tmark.NumberArea} type='text' label="Phone Number" placeholder='Phone Number' variant='outlined' fullWidth required
                                    value={data.contactPhoneNumber}
                                    onChange={(e) => handleChange1(e, 'contactPhoneNumber')}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        color: '#232536',
                                        fontWeight: '400',
                                        fontSize: '15px',
                                        lineHeight: '28px',
                                        mt: { xs: '5%', sm: '4%', md: '4%', lg: '5%', xl: '7%' },
                                        mb: '4%'

                                    }} />

                                <Button variant="contained" type="submit">Submit</Button>
                            </form>
                        </Box>
                        <h1> Or </h1>
                        <div className={Tmark.FaqsModalButton} style={{ display: 'flex', justifyContent: 'space-between', margin: '5% 0' }}>
                            <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ width: '47%', textDecoration: 'none' }}>
                                <Button sx={{
                                    display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#16469d', color: '#fff', border: 'none' },
                                }}><CallOutlinedIcon className={Tmark.icon}/>+91 93217 14104</Button></a>
                            <a href='mailto:info@kjssteel.com' style={{ width: '47%', textDecoration: 'none' }}>
                                <Button sx={{
                                    display: 'flex', justifyContent: 'center', gap: '2%', borderRadius: '4px', color: '#4E4E4E', textTransform: 'capitalize', fontWeight: '500', fontSize: '15px', width: '100%', border: '1px solid #4E4E4E', py: 0.5, '&:hover': { backgroundColor: '#16469d', color: '#fff', border: 'none' },
                                }}><EmailOutlinedIcon className={Tmark.icon}/>info@kjssteel.com</Button></a>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    )
}

export default TheMarket
