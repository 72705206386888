import CareerTabs from 'components/CareerTabs/CareerTabs'
import Footer from 'components/Footer/Footer'
import NavBar from 'components/Header/NavBar'
import OurBenefits from 'components/OurBenefits/OurBenefits'
import React from 'react'
import CareerBannerPage from 'components/CareerBannerPage/CareerBannerPage'
import { Helmet } from "react-helmet";

const CareerMainPage = () => {

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Careers | Judging Steel | Project Financing | Client's consultant</title>
        <meta name="description" content="We consult with clients to ensure they are getting the best product for their needs while keeping cost and production efficiency at their optimum." />
        <meta name="title" content="Judging Steel | Project Financing | Client's consultant" />
        <meta name="keywords" content="" />

      </Helmet>
      <div 
      onContextMenu={handleContextMenu}
      >
        <NavBar />
        <CareerBannerPage />
        <CareerTabs />
        <OurBenefits />
        <Footer />
      </div>
    </>
  )
}

export default CareerMainPage
