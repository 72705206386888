import React from 'react'
import Box from '@mui/material/Box';
import Styles from './css/ContactUsBannerPage.module.css'
import { Typography } from '@mui/material';
import { Margin, Style } from '@mui/icons-material';


function ContactUsBannerPage() {
    return (
        <div>

            <Box className={Styles.BgImg }
                sx={{
                    mt:'1%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign:'center',
                    alignItems: 'center',
                    width: '100%',
                }}>
                <Typography variant='h3'
                    sx={{
                        fontSize: '36px',
                        fontWeight: '500',
                        color: '#FFFFFF',
                        lineHeight: '42px',
                    }}>
                    Contact us</Typography>
            </Box>

           
        </div>
    )
}

export default ContactUsBannerPage