import React from 'react'
import EpcBan from "./EpcAggregatorBg.module.css"
import { Typography } from '@mui/material'

const EpcAggregatorBg = () => {
    return (
        <div>
            <div className={EpcBan.bannerStart}>
                <div className={EpcBan.banner1}>
                    <Typography variant='h2'>EPC Aggregator</Typography>
                </div>
            </div>
        </div>
    )
}

export default EpcAggregatorBg
