import React from 'react'
import DiscStyles from '../Disclaimer/Disclaimer.module.css'
import { Typography, Box } from '@mui/material'

const Disclaimer = () => {
  return (
    <div className={DiscStyles.disclaimermain}>
      <div className={DiscStyles.disclaimerStart}>
        <div className={DiscStyles.disclaimerHeader}>
          <Box className={DiscStyles.disclaimerBox}>
            <Typography variant='h3'>Disclaimer</Typography>
            <Typography variant='h4'>Updated December 05, 2022</Typography>
          </Box>
          <div className={DiscStyles.disclaimercontent}>
            <Typography variant='h6'>
              The information provided on this website is intended for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
            </Typography>
            <Typography variant='h6'>
              In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
            </Typography>
            <Typography variant='h6'>
              Through this website, you are able to link to other websites which are not under our control. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
            </Typography>
            <Typography variant='h6'>
              Every effort is made to keep the website up and running smoothly. However, we take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer